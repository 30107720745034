import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
import {
  BargainsFilter,
  BuyRent,
  ObjectType,
  AlleAnzeigen,
  Sort,
  conditionItems,
  ObjectTypeOptionApartment,
  ObjectTypeOptionHouse
} from '~/entities/bargains';
import type { pagination } from '~/entities/bargains';
@Module({
  name: 'bargainsFilter',
  stateFactory: true,
  namespaced: true,
  preserveState: false
})
export default class bargainsFiter extends VuexModule {
  filter: BargainsFilter = {
    isAuction: false,
    showFilter: true,
    buyRent: BuyRent.Buy,
    objectType: ObjectType.All,
    condition: conditionItems.all,
    postal_code: [],
    region: [],
    hasOutsideArea: false,
    alleAnzeigen: AlleAnzeigen.All,
    objectTypeOption: ObjectTypeOptionHouse.All,
    sort: Sort.empfohleneZuerst,
    createdAt: undefined,
    fromSize: { from: undefined, to: undefined },
    fromRoom: { from: undefined, to: undefined },
    sizePlotArea: { from: undefined, to: undefined },
    price: { from: undefined, to: undefined },
    sizeOutsideArea: { from: undefined, to: undefined },
    typeYearBuilt: { from: undefined, to: undefined },
    populationGrowth: { from: undefined, to: undefined },
    unemploymentRate: { from: undefined, to: undefined },
    outdoorArea: false,
    rendite: undefined,
    cherryScore: undefined,
    exactAddressKnown: false,
    amElevator: false,
    sellerPrivateInsertion: null,
    faiv: false,
    total: 0,
    count: 0,
    page: 1,
    size: 20,
    loading: false
  };

  @Mutation
  setRegionAsFilter(data: [{}]) {
    this.filter.region = data;
  }

  @Mutation
  setAlleAnzeigen(val: AlleAnzeigen) {
    this.filter.alleAnzeigen = val;
  }

  @Mutation
  setPostalCode(val: BargainsFilter['postal_code']) {
    this.filter.postal_code = val;
  }

  @Mutation
  setObjectTypeOptopn(val: ObjectTypeOptionHouse | ObjectTypeOptionApartment) {
    this.filter.objectTypeOption = val;
  }

  @Mutation
  setSort(val: Sort) {
    this.filter.sort = val;
  }

  @Mutation
  setFaiv(val: boolean) {
    this.filter.faiv = val;
  }

  @Mutation
  setCherryScore(val: number | undefined) {
    this.filter.cherryScore = val;
  }

  @Mutation
  setAmElevator(val: boolean) {
    this.filter.amElevator = val;
  }

  @Mutation
  setHasOutsideArea(val: boolean) {
    this.filter.hasOutsideArea = val;
  }

  @Mutation
  setSellerPrivateInsertion(val: boolean) {
    this.filter.sellerPrivateInsertion = val;
  }

  @Mutation
  setRendite(val: number | undefined) {
    this.filter.rendite = val;
  }

  @Mutation
  setShowFilter(val: boolean) {
    this.filter.showFilter = val;
  }

  @Mutation
  setIsAuction(val: boolean) {
    this.filter.isAuction = val;
  }

  @Mutation
  setCreatedAt(val: number | undefined) {
    this.filter.createdAt = val;
  }

  @Mutation
  setPage(val: number) {
    this.filter.page = val;
  }

  @Mutation
  setCondition(val: conditionItems) {
    this.filter.condition = val;
  }

  @Mutation
  setLoading(val: boolean) {
    this.filter.loading = val;
  }

  get filtersData() {
    return this.filter;
  }

  get getLoading() {
    return this.filter.loading;
  }

  @Mutation
  setPagination(data: pagination) {
    this.filter.count = data.count;
    this.filter.total = data.total;
    this.filter.page = data.page;
    this.filter.selected_region = data.selected_region;
  }

  @Mutation
  setRegion(region: BargainsFilter['region']) {
    this.filter.region = region;
  }

  @Mutation
  setExactAddressKnown(exactAddressKnown: boolean) {
    this.filter.exactAddressKnown = exactAddressKnown;
  }

  @Mutation
  setOutdoorArea(outdoorArea: boolean) {
    this.filter.outdoorArea = outdoorArea;
  }

  @Mutation
  setPopulationGrowthFrom(from: number | undefined) {
    this.filter.populationGrowth.from = from;
  }

  @Mutation
  setPopulationGrowthTo(to: number | undefined) {
    this.filter.populationGrowth.to = to;
  }

  @Mutation
  setTypeYearBuiltTo(to: number | undefined) {
    this.filter.typeYearBuilt.to = to;
  }

  @Mutation
  setUnemploymentRateFrom(from: number | undefined) {
    this.filter.unemploymentRate.from = from;
  }

  @Mutation
  setUnemploymentRateTo(to: number | undefined) {
    this.filter.unemploymentRate.to = to;
  }

  @Mutation
  setTypeYearBuiltFrom(from: number | undefined) {
    this.filter.typeYearBuilt.from = from;
  }

  @Mutation
  setSizeOutsideAreaTo(to: number | undefined) {
    this.filter.sizeOutsideArea.to = to;
  }

  @Mutation
  setSizeOutsideAreaFrom(from: number | undefined) {
    this.filter.sizeOutsideArea.from = from;
  }

  @Mutation
  setSizePlotAreaTo(to: number | undefined) {
    this.filter.sizePlotArea.to = to;
  }

  @Mutation
  setSizePlotAreaFrom(from: number | undefined) {
    this.filter.sizePlotArea.from = from;
  }

  @Mutation
  setFromRoomFrom(from: number | undefined) {
    this.filter.fromRoom.from = from;
  }

  @Mutation
  setFromRoomTo(to: number | undefined) {
    this.filter.fromRoom.to = to;
  }

  @Mutation
  setFromSizeFrom(from: number) {
    this.filter.fromSize.from = from;
  }

  @Mutation
  setPriceTo(to: number | undefined) {
    this.filter.price.to = to;
  }

  @Mutation
  setPriceFrom(from: number) {
    this.filter.price.from = from;
  }

  @Mutation
  setFromSizeTo(to: number) {
    this.filter.fromSize.to = to;
  }

  @Mutation
  setBuyRent(buyRent: BuyRent) {
    this.filter.buyRent = buyRent;
  }

  @Mutation
  setObjectType(objectType: ObjectType) {
    this.filter.objectType = objectType;
    this.filter.objectTypeOption = ObjectTypeOptionApartment.All;
  }
}
