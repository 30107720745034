import { render, staticRenderFns } from "./Navbar.vue?vue&type=template&id=3ddf9fe7&scoped=true&"
import script from "./Navbar.vue?vue&type=script&lang=ts&"
export * from "./Navbar.vue?vue&type=script&lang=ts&"
import style0 from "./Navbar.vue?vue&type=style&index=0&id=3ddf9fe7&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ddf9fe7",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Toast: require('/home/gitlab-runner/builds/ZDpPz_P8t/0/ireen-project/ireen-frontend/v2/components/toast/Toast.vue').default})
