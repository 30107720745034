/* eslint-disable */

import { caller } from '~/entities/refined-evaluation';

/* tslint:disable */

export interface Chart {
  stepOne: Step;
  stepTwo: Step;
  stepThree: Step;
  stepFour: Step;
}
export enum EvalutionType {
  all = 'all',
  UserEvaluations = 'Eigene Bewertungen',
  BargainEvaluations = 'Rendite Objekt Bewertungen',
  portfolio = 'portfolio'
}

export interface ContactUsRequestModel {
  /** Firstname */
  firstName?: string;

  /** Lastname */
  lastName?: string;

  /**
   * Email
   * @format email
   */
  email: string;

  /** Subject */
  subject: string;

  /** Message */
  message: string;
}

export interface CreateEvaluationListingLocalizationSchema {
  geo: ListingLocalizationGeo;

  /** Fulladdress */
  fullAddress?: string;

  address: {
    zip: string | null;
    city: string | null;
    street: string | null;
    streetNumber: string | null;
    state: string | null;
    countryCode: string | null;
    municipalityCode: string | null;
  };
}

export interface CreateEvaluationRequestSchema {
  localization: CreateEvaluationListingLocalizationSchema;
  type: ListingType;
}

export interface CreateEvaluationResponseSchema {
  /** Id */
  id: string;
  listing: CreateEvaluationRequestSchema;
  chartDetails: Chart;
}

export interface EditEvaluationAreaSchema {
  /** Cellararea */
  hasCellar?: boolean;

  /** Numberofbathrooms */
  numberOfBathrooms?: number;

  /** Numberoftoilets */
  numberOfToilets?: number;

  /** Numberofbedrooms */
  numberOfBedrooms?: number;

  /** Hasbalcony */
  hasBalcony?: boolean;

  /** Hasloggia */
  hasLoggia?: boolean;

  /** Hasterrace */
  hasTerrace?: boolean;

  /** Hasgarden */
  hasGarden?: boolean;

  /** Numberofbalconies */
  numberOfBalconies?: number;

  /** Balconyarea */
  balconyArea?: number | null;

  /** Numberofloggia */
  numberOfLoggia?: number;

  /** Loggiaarea */
  loggiaArea?: number | null;

  /** Numberofterrace */
  numberOfTerracenumberOfTerrace?: number;

  /** Terracearea */
  terraceArea?: number | null;

  /** Gardenarea */
  gardenArea?: number | null;

  primaryArea?: number;
  /** Totalarea */
  outsideArea?: number;

  /** Usable area */
  floorArea?: number;
  plotArea?: number;
  /** Livingarea */
  livingArea?: number;
  living_area?: number;
  /** Numberofrooms */
  numberOfRooms?: number;
  number_of_rooms?: number;
  /** hasTerras | hasBallcony | ... */
  hasOutsideArea?: boolean;
}

export interface EditEvaluationConditionSchema {
  energyCertification?: {
    /** Heatingdemand */
    heatingDemand?: string;

    /** Heatingdemandclass */
    heatingDemandClass?: string;
  };

  age?: Age;

  type?: RoutersListingModelConditionType;

  /** Yearofconstruction */
  yearOfConstruction?: number;
  yearOfConstructionInteger?: number;
}

export interface EditEvaluationFittingSchema {
  lift?: Lift[];
  facilities?: Facilities[];
  parking?: any;
  constructionMethod?: RoutersListingModelConstructionType[];
  shapeOfRoof?: roofTypesSchema[];
  /** Suitableforsharedapartment */
  suitableForSharedApartment?: boolean;
  bathroom?: Bathroom[];
  kitchen?: Kitchen[];
  heatingType?: HeatingType[];
  firingType?: FiringType[];
  floor?: Floor[];
  hasCellar?: caller;
  /** An enumeration. */
  furnishing?: Furnishing;
  orientation?: BalconyTerraceOrientation[];
  balconyOrientation?: BalconyTerraceOrientation[];
  loggiaOrientation?: BalconyTerraceOrientation[];
  terraceOrientation?: BalconyTerraceOrientation[];

  /** An enumeration. */
  view?: View[];

  /** An enumeration. */
  parkingSpace?: ParkingSpace[];

  /** Numberofparkingspaces */
  // numberOfParkingSpaces?: number;
  parkingSpaceCarportNumber?: number;
  parkingSpaceDuplexNumber?: number;
  parkingSpaceGarageNumber?: number;
  parkingSpaceParkingDeckNumber?: number;
  parkingSpaceBasementGarageNumber?: number;
  parkingSpaceOutsideNumber?: number;
  accessibility?: Accessibility[];
}

export interface EditEvaluationDensitySchema {
  /** X */
  x: number[];

  /** Y */
  y: number[];
}

export interface EditEvaluationListingLocalizationGeoSchema {
  /** Lat */
  lat?: number;

  /** Lon */
  lon?: number;
}

export interface EditEvaluationListingLocalizationSchema {
  geo?: EditEvaluationListingLocalizationGeoSchema;
  information?: {
    /** Numberoffloors */
    numberOfFloors?: number;

    /** Floor */
    floor?: number;
  };
  fullAddress?: any;
  address?: {
    zip: string | null;
    city: string | null;
    street: string | null;
    streetNumber: string | null;
    state: string | null;
    countryCode: string | null;
    municipalityCode: string | null;
    postalName?: string;
  };
}
export interface pictures {
  isTitlePicture?: boolean;
  url?: string;
}
export interface EditEvaluationRequestSchema {
  pictures?: Array<pictures>;
  localization?: EditEvaluationListingLocalizationSchema;
  type?: ListingType;
  condition?: EditEvaluationConditionSchema;
  area?: EditEvaluationAreaSchema;

  priceDetails?: PriceDetail;
  priceInformation?: EditRefinedEvaluationPriceInformationSchema;
  sourceUrl?: string;
  trasferType?: string;
}

export interface LimeWeights {
  value: number;
  label: {
    en: string;
    de: string;
  };
}
export interface ExposeData {
  id: string;
  evaluationId: string;
  listing: EditEvaluationRequestSchema;
}

export interface EditEvaluationResponseSchema {
  /** Id */
  bargain?: boolean;
  id: string;
  listing: EditEvaluationRequestSchema;
  expose: ExposeData;
  limeWeights: LimeWeights[] | null;
  ireenEvaluation: PriceDetail;
  density: EditEvaluationDensitySchema;
  cdf?: EditEvaluationDensitySchema;
}

export interface EditRefinedEvaluationRequestSchema {
  area?: EditEvaluationAreaSchema;
  condition?: EditEvaluationConditionSchema;
  fitting?: EditEvaluationFittingSchema;
  localization?: EditEvaluationListingLocalizationSchema;
  priceInformation?: EditRefinedEvaluationPriceInformationSchema;
  numberOfTerrace?: number;
  /** Type */
  type?: ListingType;
  description?: Description;

  meta?: {
    IREENuserIsOwner?: boolean;
  };

  object?: {
    isRented?: boolean;
    isRentedUnlimited?: boolean;
    isRentedLimited?: boolean;
  };

  /** Pictures */
  pictures?: Pictures[];
}

export interface ErrorModel {
  /** Uuid */
  uuid: string;

  /** Status */
  status: number;

  /** Error */
  error: string;

  /** Details */
  details?: string[];

  /** Timestamp */
  timestamp: number;

  /** Payload */
  payload?: object;

  /** Stacktrace */
  stacktrace?: string;
}

/**
 * An enumeration.
 */
export enum EvaluationQueryTypeEnum {
  BASIC = 'BASIC',
  REFINED = 'REFINED'
}

export interface GetEvaluationListResponseSchema {
  /** Evaluations */
  evaluations: GetEvaluationResponseSchema[];
}

export interface GetEvaluationResponseSchema {
  /** Id */
  bargain?: boolean;
  isBargain?: boolean;
  id: string;
  listing: EditEvaluationRequestSchema;
  priceDetails: PriceDetail;
}

export interface HTTPValidationError {
  /** Detail */
  detail?: ValidationError[];
}

export interface ListingLocalizationGeo {
  /** Lat */
  lat: number;

  /** Lon */
  lon: number;
}

export interface PropertyTypeAttribute {
  /** Isvacationproperty */
  isVacationProperty?: boolean;
}

export interface ListingType {
  propertyTypeAttribute?: PropertyTypeAttribute;

  /** property type */
  estateType?: ListingTypeEstateTypeEnum;

  /** property category */
  estateDetailType?: ListingTypeEstateDetailTypeEnum;
  transferType?: ListingTypeTransferType;
}

/**
 * An enumeration.
 */
export enum ListingTypeEstateTypeEnum {
  HOUSE = 'HOUSE',
  APARTMENT = 'APARTMENT'
}

/**
 * An enumeration.
 */
export enum ListingTypeTransferType {
  RENT = 'RENT',
  BUY = 'BUY'
}

export enum Age {
  NEWBUILDING = 'NEWBUILDING',
  OLDBUILDING = 'OLDBUILDING'
}

export enum elevatorOptions {
  PASSENGER_ELEVATOR = 'LIFT',
  FREIGHT_ELEVATOR = 'FREIGHT_LIFT'
}
export enum Lift {
  LIFT = 'LIFT'
}

export enum Facilities {
  CELLAR = 'CELLAR',
  CELLAR_PARTIAL = 'CELLAR_PARTIAL',
  BIKE_ROOM = 'BIKE_ROOM',
  STOREROOM = 'STOREROOM',
  WASHING_DRYING_ROOM = 'WASHING_DRYING_ROOM',
  GUEST_TOILET = 'GUEST_TOILET',
  FIREPLACE = 'FIREPLACE',
  AIR_CONDITIONING = 'AIR_CONDITIONING',
  SWIMMING_POOL = 'SWIMMING_POOL',
  WINTER_GARDEN = 'WINTER_GARDEN',
  SHUTTER = 'SHUTTER',
  SAUNA = 'SAUNA',
  gardenAvailable = 'gardenAvailable',
  balconyAvailable = 'balconyAvailable',
  loggiaAvailable = 'loggiaAvailable',
  terrasseAvailable = 'terrasseAvailable',
  parkingSpaceAvailable = 'parkingSpaceAvailable',
  basementGarage = 'basementGarage',
  carport = 'carport',
  duplex = 'duplex',
  garage = 'garage',
  freeParkingSpace = 'freeParkingSpace',
  parkingDeck = 'parkingDeck'
}

export enum HeatingType {
  CENTRAL_HEATING = 'CENTRAL_HEATING',
  DISTRICT_HEATING = 'DISTRICT_HEATING',
  FLOOR_HEATING = 'FLOOR_HEATING',
  SELF_CONTAINED_CENTRAL_HEATING = 'SELF_CONTAINED_CENTRAL_HEATING',
  STOVE_HEATING = 'STOVE_HEATING'
}

export enum Kitchen {
  OPEN_KITCHEN = 'OPEN_KITCHEN',
  FITTED_KITCHEN = 'FITTED_KITCHEN',
  KITCHENETTE = 'KITCHENETTE',
  NO_KITCHEN = null
}

export enum FiringType {
  AIR_HEAT = 'HEAT_PUMP_AIR',
  ALTERNATIVE = 'ALTERNATIVE',
  BLOCK_HEATING = 'BLOCK_HEATING',
  COAL = 'COAL',
  DISTRICT_HEATING = 'DISTRICT_HEATING',
  ELECTRICITY = 'ELECTRICITY',
  GAS = 'GAS',
  GEOTHERMAL = 'GEOTHERMAL',
  OIL = 'OIL',
  PELLET_HEATING = 'PELLET_HEATING',
  SOLAR_HEATING = 'SOLAR_HEATING',
  WATER_ELECTRICITY_HEATING = 'WATER_ELECTRICITY_HEATING'
}

export enum Furnishing {
  FULL = 'FULL',
  PART = 'PART',
  FURNISHED = 'FURNISHED',
  NULL = null
}

export enum MiscellaneousOptions {
  BICYCLE_STORAGE_ROOM = 'BIKE_ROOM',
  WASHROOM = 'WASHING_DRYING_ROOM',
  GUEST_TOILET = 'GUEST_TOILET',
  SAUNA = 'SAUNA',
  POOL = 'SWIMMING_POOL',
  CONSERVATORY = 'WINTER_GARDEN',
  AIR_CONDITIONER = 'AIR_CONDITIONING',
  CHIMNEY = 'FIREPLACE',
  BARRIER_FREE = 'HANDICAPPED_ACCESSIBLE',
  SHUTTERS = 'SHUTTER',
  SUITABLE_FOR_SHARED_FLATS = 'SUITABLE_FOR_SHARED_APARTMENT'
}

export enum View {
  AFAR = 'AFAR',
  LAKE = 'LAKE',
  MOUNTAINS = 'MOUNTAINS'
}

export enum ParkingSpace {
  BASEMENT_GARAGE = 'BASEMENT_GARAGE',
  CARPORT = 'CARPORT',
  DUPLEX = 'DUPLEX',
  GARAGE = 'GARAGE',
  PARKING_SPACE = 'PARKING_SPACE',
  PARKING_DECK = 'PARKING_DECK'
}

export enum Accessibility {
  HANDICAPPED_ACCESSIBLE = 'HANDICAPPED_ACCESSIBLE'
}

export enum BalconyTerraceOrientation {
  NORTH = 'NORTH',
  NORTH_EAST = 'NORTH_EAST',
  NORTH_WEST = 'NORTH_WEST',
  SOUTH = 'SOUTH',
  SOUTH_EAST = 'SOUTH_EAST',
  SOUTH_WEST = 'SOUTH_WEST',
  EAST = 'EAST',
  WEST = 'WEST'
}

export enum Floor {
  BOARDS = 'BOARDS',
  CARPET = 'CARPET',
  GRANITE = 'GRANITE',
  LAMINATE = 'LAMINATE',
  LINOLEUM = 'LINOLEUM',
  MARBLE = 'MARBLE',
  PARQUET = 'PARQUET',
  PLASTIC = 'PLASTIC',
  SCREED = 'SCREED',
  STONE = 'STONE',
  TERRACOTTA = 'TERRACOTTA',
  TILES = 'TILES'
}

export enum Bathroom {
  BATHTUBE = 'BATHTUBE',
  BIDET = 'BIDET',
  WINDOW = 'WINDOW',
  SHOWER = 'SHOWER',
  URINAL = 'URINAL'
  // SAUNA = 'SAUNA'
}

export enum RoutersListingModelConditionType {
  FIRST_TIME_USE = 'FIRST_TIME_USE',
  REFURBISHED = 'REFURBISHED',
  MINT_CONDITION = 'MINT_CONDITION',
  MODERNIZED = 'MODERNIZED',
  FULLY_RENOVATED = 'FULLY_RENOVATED',
  NEED_OF_RENOVATION = 'NEED_OF_RENOVATION',
  WELL_KEPT = 'WELL_KEPT',
  UNKNOWN = null
}

export enum roofTypesSchema {
  GABLED_ROOF = 'SADDLEBACK_ROOF',
  FLACHDACH = 'FLAT_ROOF',
  PENT_ROOF = 'PENT_ROOF',
  MANSARD_ROOF = 'MANSARD_ROOF',
  HIP_ROOF = 'HIPPED_ROOF',
  HALF_HIPPED_ROOF = 'HALF_HIPPED_ROOF',
  PYRAMID_ROOF = 'PYRAMID_ROOF'
}

export enum RoutersListingModelConstructionType {
  MASSIVE = 'MASSIVE',
  PREFABRICATED = 'PREFABRICATED',
  WOOD = 'WOOD',
  UNKNOWN = null
}

export interface PartialEditEvaluationRequestSchema {
  condition?: EditEvaluationConditionSchema;
  area?: EditEvaluationAreaSchema;
}

export interface HeatingCosts {
  /** Total */
  total?: number;
  net?: number;
  tax?: number;
  showGross?: boolean;
}

export interface OperationalCosts {
  /** Total */
  total?: number;
  net?: number;
  tax?: number;
  showGross?: boolean;
}

export interface ElectricityCosts {
  /** Total */
  total?: number;
  net?: number;
  tax?: number;
  showGross?: boolean;
}

export interface MiscCosts {
  /** Total */
  total?: number;
  net?: number;
  tax?: number;
  showGross?: boolean;
}

export interface Costs {
  heatingCosts?: HeatingCosts;
  operationalCosts?: OperationalCosts;
  electricityCosts?: ElectricityCosts;
  miscCosts?: MiscCosts;
  monthlyCosts?: any;
}

export interface EditRefinedEvaluationPriceInformationSchema {
  costs?: Costs;
  prices?: any;
  primaryPrice?: number;
  IREENUser?: {
    leaseholdTerm?: boolean;
    leaseholdTermEnd?: string;
    intendedSellingPrice: number;
    transactionPriceHistory?: {
      price?: number;
      date?: string;
    }[];
    currentMainRent?: number;
    currentRentContractStart?: string;
  };
}

export interface Description {
  /** Title */
  title?: string;

  /** Descriptionnote */
  descriptionNote?: string;
}

export enum PicType {
  INTERIOR = 'INTERIOR',
  EXTERIOR = 'EXTERIOR',
  FLOOR_PLAN = 'FLOOR_PLAN',
  SITE_PLAN = 'SITE_PLAN'
}
export enum kindOption {
  LIMITED = 'Temporary',
  INDEFINITE = 'Unlimited',
  UNKNOWN = 'Unknown'
}

export interface Pictures {
  /** Title */
  title?: string;

  /** Url */
  url?: string;

  /** An enumeration. */
  type?: PicType;

  /** Istitlepicture */
  isTitlePicture?: boolean;
}

export interface PartialEditEvaluationResponseSchema {
  /** Id */
  id: string;
  listing: EditEvaluationRequestSchema;
  ireenEvaluation?: PriceDetail;
  density?: EditEvaluationDensitySchema;
  cdf?: EditEvaluationDensitySchema;
}

export interface PriceDetail {
  buy: {
    basic: {
      /** Rangemean */
      rangeMean: number;

      /** Rangemax */
      rangeMax: number;

      /** Rangemin */
      rangeMin: number;
    };

    refined: {
      /** Rangemean */
      rangeMean: number;

      /** Rangemax */
      rangeMax: number;

      /** Rangemin */
      rangeMin: number;
    };
    primaryPrice?: number;
  };
}

export interface Step {
  /** Min */
  min: number;

  /** Max */
  max: number;

  /** Items */
  items: number[];

  /** Columns */
  columns: StepColumn[];

  /** Display Max */
  display_max?: number;
}

export interface StepColumn {
  /** Id */
  id: number;

  /** Height */
  height: number;

  /** Val */
  val: number;

  hide: boolean;
}

export interface ValidationError {
  /** Location */
  loc: string[];

  /** Message */
  msg: string;

  /** Error Type */
  type: string;
}

export interface PortfolioMeta {
  userCanAddItems: boolean;
}

export interface Portfolio {
  id: String;
  transferType: ListingTypeTransferType | null;
  meta: PortfolioMeta;
  metrics: null;
  portfolio: EditEvaluationResponseSchema[];
  portfolioEvaluation: any;
  title: String;
  uid: String;
}

export enum ListingTypeEstateDetailTypeEnum {
  // Apartment
  GROUND_FLOOR = 'GROUND_FLOOR',
  MAISONETTE = 'MAISONETTE',
  FLOOR_APARTMENT = 'FLOOR_APARTMENT',
  ROOF_STOREY = 'ROOF_STOREY',
  PENTHOUSE = 'PENTHOUSE',
  GARCONNIERE = 'GARCONNIERE',
  LOFT = 'LOFT',

  // House
  SEMIDETACHED_HOUSE = 'SEMIDETACHED_HOUSE',
  SINGLE_FAMILY_HOUSE = 'SINGLE_FAMILY_HOUSE',
  MULTI_FAMILY_HOUSE = 'MULTI_FAMILY_HOUSE',
  COUNTRY_HOUSE = 'COUNTRY_HOUSE',
  FARMHOUSE = 'FARMHOUSE'
}
