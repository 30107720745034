import { Module, Mutation, VuexModule } from 'vuex-module-decorators';

export interface User {
  uid?: string;
  email: string | null;
  emailVerified: boolean;
  claims?: any;
  disclaimer?: boolean;
  accessToken?: string;
  stayLoggedIn?: boolean;
  hasSubscription?: boolean;
}

export interface Profile {
  avatar?: string;
  firstName?: string;
  lastName?: string;
  zip?: string;
  city?: string;
}

export enum NotificationFilterEnum {
  ValueChanges = 'VALUE_CHANGES',
  NewFunctions = 'NEW_FUNCTIONS',
  ObjectNotifications = 'OBJECT_NOTIFICATIONS',
  NewComparables = 'NEW_COMPARABLES'
}

export enum NotificationFrequencyEnum {
  Daily = 'DAILY',
  Weekly = 'WEEKLY',
  Monthly = 'MONTHLY',
  Never = 'NEVER'
}

export interface NotificationSettings {
  frequency?: NotificationFrequencyEnum;
  filters?: NotificationFrequencyEnum[];
  inBrowser?: boolean;
  viaEmail?: boolean;
}

@Module({
  stateFactory: true,
  preserveState: true,
  name: 'userProfile',
  namespaced: true
})
export default class UserProfile extends VuexModule {
  user: User | null = null;
  profile: Profile = {};
  notificationSettings: NotificationSettings = {};
  redirectTo: string = '';
  get getUser(): User | null {
    return this.user;
  }

  get isLoggedIn(): boolean {
    return !!this.user?.uid;
  }

  @Mutation
  setRedirectTo(value: string) {
    this.redirectTo = value;
  }

  @Mutation
  setDisclaimer(value: boolean) {
    this.user?.disclaimer = value;
  }

  @Mutation
  SIGN_OUT(): void {
    this.user?.email = null;
    this.user = null;
    this.profile = {};
    this.notificationSettings = {};
  }

  @Mutation
  SET_USER(user: User | null): void {
    this.user = user;
  }

  @Mutation
  SET_ACCESS_TOKEN(accessToken: string): void {
    this.user = {
      ...(this.user || {}),
      accessToken
    } as User;
  }

  @Mutation
  SET_PROFILE(profile: Profile): void {
    this.profile = {
      avatar:
        profile?.avatar ||
        'https://www.pngall.com/wp-content/uploads/5/User-Profile-PNG-Clipart.png',
      firstName: profile?.firstName,
      lastName: profile?.lastName,
      zip: profile?.zip,
      city: profile?.city
    };
  }

  @Mutation
  SET_NOTIFICATION_SETTINGS(settings: NotificationSettings | {} = {}): void {
    this.notificationSettings = settings;
  }

  @Mutation
  ON_AUTH_STATE_CHANGED_MUTATION({ authUser }: any): void {
    const accessToken = authUser?.auth?.currentUser?.accessToken;
    if (accessToken) {
      this.user = {
        ...(this.user || {}),
        accessToken
      } as User;
    }
  }
}
