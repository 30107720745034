
import { Component, Vue, Watch } from 'nuxt-property-decorator';
import UserProfileTooltip from './user-profile-tooltip/UserProfileTooltip.vue';
import { Store } from '~/decorators';
import UserProfile from '~/store/userProfile';
import EvaluationsStore from '~/store/evaluations';
import PropertyTypeSearchStore from '~/store/propertyTypeSearch';
import LeavingExpose from '~/components/dialogs/leaving-expose/LeavingExpose.vue';
import AlertTypeEnum from '~/components/toast/toastEnum';
import Toast from '~/components/toast/Toast.vue';
@Component({
  components: {
    UserProfileTooltip,
    LeavingExpose,
    Toast
  }
})
export default class Navbar extends Vue {
  @Store() userProfile!: UserProfile;
  @Store() propertyTypeSearchStore!: PropertyTypeSearchStore;
  @Store() evaluationsStore!: EvaluationsStore;
  closeNewEvaluationDialog = false;
  abortEvaluationDialog = false;
  leavingExposeWizard = false;
  dashboard = false;
  clipped = false;
  drawer = false;
  profile = false;
  fixed = false;
  miniVariant = false;
  right = true;
  rightDrawer = true;
  title = 'IREEN';
  loaded = false;
  settingPage = false;
  dashboardItems = [
    {
      icon: 'mdi-chart-bar',
      title: 'Objekt Radar',
      to: '/app/objekt-radar'
    },
    {
      icon: 'mdi-chart-bar',
      title: 'Bewertung',
      to: '/app/bewertung'
    },
    {
      icon: 'mdi-chart-bar',
      title: 'Portfolio',
      to: '/app/portfolio'
    }
  ];

  auth = {
    title: {
      de: 'Abmelden',
      en: 'Sign Out'
    }
  };

  get showNav() {
    const route = this.$route;
    if (
      (route.path === '/at/app/bewertung' ||
        route.path === '/at/app/portfolio') &&
      route.hash !== '' &&
      (this.$vuetify.breakpoint.mdAndDown || window.innerWidth <= 960)
    ) {
      return false;
    } else {
      return true;
    }
  }

  get profileItemsLoggedin() {
    return [
      {
        icon: require('~/assets/img/navbar-logo/accountSettings.svg'),
        title: {
          de: 'Konto Einstellungen',
          en: 'Account Settings'
        },
        link: {
          en: '/settings',
          de: '/app/einstellungen'
        }
      },
      {
        icon: require('~/assets/img/navbar-logo/purchases.svg'),
        title: {
          en: 'Purchases',
          de: 'Einkäufe'
        },
        link: {
          en: '/settings#purchases',
          de: '/app/einstellungen#purchases'
        }
      }
    ];
  }

  routes: Record<string, any> = {
    about: {
      en: '/en/about',
      de: '/ueber-uns'
    },
    'ueber-uns': {
      en: '/en/about',
      de: '/ueber-uns'
    },
    careers: {
      en: '/en/careers',
      de: '/karriere'
    },
    karriere: {
      en: '/en/careers',
      de: '/karriere'
    },

    contact: {
      en: '/en/contact',
      de: '/kontakt'
    },
    kontakt: {
      en: '/en/contact',
      de: '/kontakt'
    },
    'evaluation-results': {
      en: '/en/evaluation-results',
      de: '/basisbewertung'
    },
    basisbewertung: {
      en: '/en/evaluation-results',
      de: '/basisbewertung'
    },
    dashboard: {
      en: '/en/app/bewertung',
      de: '/app/bewertung'
    },
    imprint: {
      en: '/en/imprint',
      de: '/impressum'
    },
    impressum: {
      en: '/en/imprint',
      de: '/impressum'
    }
  };

  get stepPropertyTypeSearch() {
    return this.$store.state.propertyTypeSearch.step;
  }

  get hideMenuBar() {
    return (
      this.$route.path.includes('einstellungen') ||
      this.$route.path.includes('objekt-radar/details') ||
      (this.$route.path.includes('vermarkten') &&
        this.stepPropertyTypeSearch > 0) ||
      (this.$route.hash.includes('#new_evaluation') &&
        this.$store.state.propertyTypeSearch.newEvaluation) ||
      this.$route.hash.includes('#objektdaten') ||
      this.$route.hash.includes('#expose-wizard')
    );
  }

  get hasEvaluation() {
    return this.evaluationsStore.hasEvaluations;
  }

  dashboardItemsFunc() {
    if (!this.isLoggedIn || !this.hasEvaluation) {
      this.dashboardItems = [];
    }
  }

  get items() {
    return [
      {
        icon: 'mdi-chart-bar',
        title: this.$i18n.t('navbar.bargains.title'),
        to: this.$i18n.t('navbar.bargains.link')
      },
      {
        icon: 'mdi-chart-bar',
        title: this.$i18n.t('navbar.features.title'),
        to: this.$i18n.t('navbar.features.link')
      },
      {
        icon: 'mdi-chart-bar',
        title: this.$i18n.t('navbar.partner.title'),
        to: this.$i18n.t('navbar.partner.link'),
        dropDownItems: [...this.partner, ...this.features]
      },
      {
        icon: 'mdi-chart-bar',
        title: this.$i18n.t('navbar.wissen.title'),
        to: this.$i18n.t('navbar.wissen.link')
      }
    ];
  }

  get features() {
    return [
      // {
      //   title: 'Immobilien bewerten',
      //   link: '/vermarkten/immobilien-bewerten'
      // },
      {
        title: 'Anzeige erstellen',
        link: '/vermarkten/anzeige-erstellen'
      },
      {
        title: 'Anzeige veröffentlichen',
        link: '/vermarkten/anzeige-veröffentlichen'
      }
    ];
  }

  get partner() {
    const pageName = [];
    for (const i in this.$store.state.partnerPage.pages) {
      const page = this.$store.state.partnerPage?.pages[i]?.pageData?.name;
      pageName.push({ title: page, link: `/produkte/${page}` });
    }
    return pageName;
  }

  get profileItems() {
    return [
      {
        title: this.$i18n.t('profile.dashboard.title'),
        to: this.$i18n.t('profile.dashboard.link')
      },
      {
        title: this.$i18n.t('profile.accountSettings.title'),
        to: this.$i18n.t('profile.accountSettings.link')
      }
      // {
      //   title: this.$i18n.t('profile.notificationSettings.title'),
      //   to: this.$i18n.t('profile.notificationSettings.link')
      // }
    ];
  }

  gotToSettingPage() {
    // this.$router.push(
    //   `/${this.getLang()}${this.$i18n.t('profile.accountSettings.link')}`
    // );
    this.$router.push(`/${this.getLang()}/app/bewertung`);
  }

  async logout() {
    await this.$fire.auth
      .signOut()
      .then(() => {
        this.userProfile.SIGN_OUT();
        window.location.replace('https://ireen.com');
      })
      .catch((error) => {
        this.$store.commit('toast/showToast', {
          alertType: AlertTypeEnum.Error,
          message: error.message,
          timeout: 5000
        });
      });
  }

  private _goHome() {
    this.$router.push(`/${this.getLang()}/app/bewertung`);
  }

  get user() {
    // return null;
    return {
      avatar: this.userProfile?.profile?.avatar,
      firstName: this.userProfile?.profile?.firstName,
      lastName: this.userProfile?.profile?.lastName,
      email: this.$fire.auth.currentUser?.email
      // avatar: null,
      // firstName: null,
      // lastName: null,
      // email: null
    };
  }

  get langs() {
    return [
      {
        val: 'de',
        title: this.$i18n.t('navbar.german'),
        to: this.routeGenerator('de')
      },
      {
        val: 'en',
        title: this.$i18n.t('navbar.english'),
        to: this.routeGenerator('en')
      }
    ];
  }

  get isLoggedIn(): boolean {
    return (
      !!this.$fire.auth.currentUser?.email &&
      !this.$fire.auth.currentUser.isAnonymous
    );
  }

  @Watch('$route')
  checkSettingPage() {
    if (this.$route.path.includes('einstellungen')) {
      this.settingPage = true;
    } else {
      this.settingPage = false;
    }
  }

  @Watch('$route')
  onRouteChange() {
    if (
      this.$route.path.includes('app') ||
      this.$route.path.includes('settings') ||
      this.$route.path.includes('einstellungen')
    ) {
      if (this.dashboardItems.length === 0) {
        this.dashboardItemsFunc();
      }
      this.dashboard = true;
    } else {
      this.dashboard = false;
    }
    if (this.$route.hash === '#basisdaten') {
      this.removeLinkActiveColor();
    } else if (this.$route.hash !== '#objektdaten') {
      this.removeLinkActiveColor();
    } else if (this.$route.path.includes('verification-emai')) {
      this.dashboard = false;
    }
  }

  removeLinkActiveColor() {
    setTimeout(() => {
      document
        .getElementById('objectDetails')
        ?.classList.remove('nuxt-link-exact-active');
    }, 500);
  }

  dashboardCheker() {
    if (
      this.$route.path.includes('app') ||
      this.$route.path.includes('settings') ||
      this.$route.path.includes('einstellungen')
    ) {
      this.dashboard = true;
    } else {
      this.dashboard = false;
    }
  }

  routeGenerator(lang: string) {
    const path = this.$route.path;
    const splitedPath = path.split('/');
    const pageName = splitedPath[splitedPath.length - 1];
    let routeGenerated;
    if (lang !== 'en') {
      routeGenerated = this.routes[pageName]?.de ?? '/';
    } else {
      routeGenerated = this.routes[pageName]?.en ?? '/en';
    }
    return routeGenerated;
  }

  goToregister() {
    this.$router.push(`/${this.getLang()}/app/register`);
  }

  getLang() {
    return this.$store.state.i18n.locale || '';
  }

  backStep() {
    this.$store.commit('propertyTypeSearch/resetStep');
  }

  closeLeaveingExpose() {
    this.leavingExposeWizard = false;
  }

  redirectToLandingPageOrDashboard() {
    if (
      this.propertyTypeSearchStore.newEvaluation &&
      this.$route.hash === '#new_evaluation' &&
      !this.closeNewEvaluationDialog &&
      !this.propertyTypeSearchStore.landingPage
    ) {
      this.closeNewEvaluationDialog = true;
    } else if (this.$route.hash === '#expose-wizard') {
      this.leavingExposeWizard = true;
    } else {
      this.closeNewEvaluationDialog = false;
      this.closeLeaveingExpose();

      if (
        (this.$route.path.includes('app') &&
          !this.$route.path.includes('einstellungen')) ||
        this.$route.path.includes('settings')
      ) {
        if (
          this.$route.hash !== '' &&
          !this.propertyTypeSearchStore.landingPage
        ) {
          this._goToDashboard();
        } else {
          this._goToHomeRoute();
        }
        this.propertyTypeSearchStore.setNewEvaluation(false);
        this.propertyTypeSearchStore.setStep(0);
      } else if (this.$route.path.includes('einstellungen')) {
        if (this.$store.state?.bargains?.list?.length > 0) {
          this.$router.push('/at/app/objekt-radar');
        } else {
          this._goToDashboard();
        }
      } else if (this.stepPropertyTypeSearch === 0) {
        this.backStep();
        this._goToHomeRoute();
      } else if (this.propertyTypeSearchStore.newEvaluation) {
        this.abortEvaluationDialog = true;
      }
    }
    this.$store.commit('propertyTypeSearch/setLandingPage', false);
  }

  private _goToHomeRoute() {
    this.$router.push(`/${this.getLang()}/app/bewertung`);
  }

  private _goToDashboard() {
    this.$router.push(`/${this.getLang()}/app/bewertung`);
  }

  gotoHomePage() {
    this.abortEvaluationDialog = false;
    this.backStep();
    this._goToHomeRoute();
  }

  hiddenWissen(title: string) {
    return title.toLowerCase() === 'wissen' && this.getLang() === 'en';
  }

  mounted() {
    this.$store.commit('userProfile/setRedirectTo', '');
    this.loaded = true;
    this.backStep();
    this.checkSettingPage();
  }

  created() {
    this.dashboardCheker();
  }
}
