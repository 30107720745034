import { render, staticRenderFns } from "./default.vue?vue&type=template&id=71840e4e&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Navbar: require('/home/gitlab-runner/builds/ZDpPz_P8t/0/ireen-project/ireen-frontend/v2/components/navbar/Navbar.vue').default})
