import { MiscellaneousOptions } from '~/schema/api';

export enum PolarOptions {
  No,
  Yes,
  Unknown
}

export enum ConditionOptions {
  FIRST_TIME_USE,
  REFURBISHED,
  MINT_CONDITION,
  MODERNIZED,
  FULLY_RENOVATED,
  NEED_OF_RENOVATION,
  WELL_KEPT,
  UNKNOWN
}

export enum Construction {
  MASSIVE,
  PREFABRICATED,
  WOOD,
  UNKNOWN
}

export enum KitchenOptions {
  OPEN_KITCHEN,
  FITTED_KITCHEN,
  KITCHENETTE,
  NO_KITCHEN
}

export enum HeatingTypeOptions {
  CENTRAL_HEATING,
  DISTRICT_HEATING,
  FLOOR_HEATING,
  SELF_CONTAINED_CENTRAL_HEATING,
  STOVE_HEATING
}

export enum FiringTypeOptions {
  AIR_HEAT,
  ALTERNATIVE,
  BLOCK_HEATING,
  COAL,
  DISTRICT_HEATING,
  ELECTRICITY,
  GAS,
  GEOTHERMAL,
  OIL,
  PELLET_HEATING,
  SOLAR_HEATING,
  WATER_ELECTRICITY_HEATING
}

export enum FloorTypeOptions {
  BOARDS,
  CARPET,
  GRANITE,
  LAMINATE,
  LINOLEUM,
  MARBLE,
  PARQUET,
  PLASTIC,
  SCREED,
  STONE,
  TERRACOTTA,
  TILES
}

export enum OutdoorAreasOptions {
  BALCONY,
  LOGGIA,
  TERRACE,
  GARDEN
}

export enum OrientationOptions {
  NORTH,
  NORTH_EAST,
  NORTH_WEST,
  SOUTH,
  SOUTH_EAST,
  SOUTH_WEST,
  EAST,
  WEST
}

export enum ViewOptions {
  AFAR,
  SEE,
  MOUNTAINS
}

export enum ParkingSpaceOptions {
  BASEMENT_GARAGE,
  CARPORT,
  DUPLEX,
  GARAGE,
  PARKING_SPACE,
  PARKING_DECK
}

export enum roofTypes {
  GABLED_ROOF,
  FLACHDACH,
  PENT_ROOF,
  MANSARD_ROOF,
  HIP_ROOF,
  HALF_HIPPED_ROOF,
  PYRAMID_ROOF
}

export enum elevator {
  PASSENGER_ELEVATOR,
  FREIGHT_ELEVATOR
}

export enum caller {
  yes,
  no,
  unknown
}

export enum Miscellaneous {
  BICYCLE_STORAGE_ROOM,
  WASHROOM,
  GUEST_TOILET,
  SAUNA,
  POOL,
  CONSERVATORY,
  AIR_CONDITIONER,
  CHIMNEY,
  BARRIER_FREE,
  SHUTTERS,
  SUITABLE_FOR_SHARED_FLATS
}
export enum kind {
  LIMITED,
  INDEFINITE,
  UNKNOWN
}
export enum furnishing {
  FULL,
  PART,
  FURNISHED,
  NULL
}

export enum BathroomOption {
  BATHTUBE,
  SHOWER,
  window,
  BIDET,
  URINAL
}

export enum kitchenStyle {
  OFFENE_KUCHE,
  EINBAUKUCHE,
  KUCHENZEILE,
  KITCHENETTE
}

export interface IRefinedEvaluation {
  HWB?: number;
  hasGarden?: PolarOptions;
  accessibility?: any[];
  hasBalcony?: PolarOptions;
  hasLoggia?: PolarOptions;
  hasTerrace?: PolarOptions;
  hasOutsideArea?: PolarOptions;
  facilities?: MiscellaneousOptions[];
  gardenAvailable?: PolarOptions;
  kitchenStyle?: kitchenStyle[];
  furnishing?: furnishing;
  constructionMethod?: Construction[];
  estateType?: string;
  MonthlyRunningCosts?: string | null;
  monthlyReserves?: string;
  kind?: kind[];
  hasCellar?: caller;
  miscellaneous?: Miscellaneous;
  shapeOfRoof?: roofTypes[];
  estateDetailType?: string;
  yearOfConstructionInteger?: number;
  heatingDemand?: string;
  heatingDemandClass?: string;
  numberOfFloors?: number;
  floor?: number;
  lift?: elevator[];
  isOwner?: PolarOptions;
  intendedSellingPrice?: number;
  transactionPriceHistory?: {
    price?: number;
    date?: string;
  }[];
  isRented?: PolarOptions;
  rentPrice?: number;
  rentDate?: string;
  fixedTermLease?: PolarOptions;
  fixedTermLeaseDate?: string;
  heatingCosts?: number;
  heatingTax?: number;
  heatingGross?: boolean;
  operationalCosts?: number;
  operationalTax?: number;
  operationalGross?: boolean;
  electricityCosts?: number;
  electricityTax?: number;
  electricityGross?: boolean;
  miscCosts?: number;
  additionalTax?: number;
  miscCostsGross?: boolean;
  cellar?: PolarOptions;
  cellarArea?: number;
  bikeRoom?: PolarOptions;
  storeRoom?: PolarOptions;
  washingDryingRoom?: PolarOptions;
  suitableForSharedApartment?: boolean;
  isVacationProperty?: PolarOptions;
  apartmentOrientation?: OrientationOptions[];
  newBuilding?: PolarOptions;
  firstTimeUse?: PolarOptions;
  condition?: ConditionOptions;
  numberOfBathrooms?: number;
  bathroom?: BathroomOption[];
  bathroomBathTube?: PolarOptions;
  bathroomBidet?: PolarOptions;
  bathroomWindow?: PolarOptions;
  bathroomShower?: PolarOptions;
  numberOfToilets?: number;
  guestToilet?: PolarOptions;
  bathroomUrinal?: PolarOptions;
  numberOfBedrooms?: number;
  kitchen?: KitchenOptions[];
  heatingType?: HeatingTypeOptions[];
  firingType?: FiringTypeOptions[];
  floorType?: FloorTypeOptions[];
  sauna?: PolarOptions;
  fireplace?: PolarOptions;
  airConditioning?: PolarOptions;
  furnished?: furnishing;
  outdoorAreas?: OutdoorAreasOptions[];
  numberOfBalconies?: number;
  balconyArea?: number | null;

  balconyOrientation?: OrientationOptions[];
  numberOfLoggia?: number;
  loggiaArea?: number | null;
  loggiaOrientation?: OrientationOptions[];
  numberOfTerrace?: number | null;
  terraceArea?: number | null;
  terraceOrientation?: OrientationOptions[];
  gardenArea?: number | null;
  gardenOrientation?: OrientationOptions[];
  view?: ViewOptions[];
  parkingSpace?: ParkingSpaceOptions[];
  numberOfParkingSpaces?: number;
  numberOfBasementGarageSpaces?: number;
  numberOfCarportSpaces?: number;
  numberOfDuplexSpaces?: number;
  numberOfGarageSpaces?: number;
  numberOfParkingDeckSpaces?: number;
  handicappedAccessible?: PolarOptions;
  swimmingPool?: PolarOptions;
  winterGarden?: PolarOptions;
  shutter?: PolarOptions;
  gardenSize?: string | number;
  balconyAvailable?: PolarOptions;
  loggiaAvailable?: PolarOptions;
  parkingSpaceAvailable?: PolarOptions;
  terrasseAvailable?: PolarOptions;
  basementGarage?: PolarOptions;
  carport?: PolarOptions;
  duplex?: PolarOptions;
  garage?: PolarOptions;
  freeParkingSpace?: PolarOptions;
  parkingDeck?: PolarOptions;
}
