export enum BuyRent {
  Buy = 'buy',
  Rent = 'rent'
}
export enum ObjectType {
  All = 'ALL',
  Home = 'home',
  Apratment = 'apartment'
}
export enum AlleAnzeigen {
  All = 'Alle anzeigen',
  Wien = 'Wien',
  Niederösterreich = 'Niederösterreich',
  Oberösterreich = 'Oberösterreich',
  Burgenland = 'Burgenland',
  Steiermark = 'Steiermark',
  Kärnten = 'Kärnten',
  Salzburg = 'Salzburg',
  Tirol = 'Tirol',
  Vorarlberg = 'Vorarlberg'
}
export enum conditionItems {
  'all' = 'ALL',
  'first-time-use' = 'FIRST_TIME_USE',
  'refurbished' = 'REFURBISHED',
  'mint-condition' = 'MINT_CONDITION',
  'well-kept' = 'WELL_KEPT',
  'need-of-Renovation' = 'NEED_OF_RENOVATION'
}

export enum Sort {
  empfohleneZuerst = 'cherry_score',
  billigeZuerst = 'price_pred',
  teureZuerst = 'price_pred',
  höchsteZuerst = 'price_return',
  neuesteZuerst = 'created_at',
  altesteZuerst = 'created_at',
  niedrigsteZuerst = 'purchase_price',
  teuersteZuerst = 'purchase_price'
}

export enum ListingTypeEstateTypeEnum {
  ALL = 'ALL',
  HOUSE = 'HOUSE',
  APARTMENT = 'APARTMENT'
}

export enum BargainComponent {
  main,
  startTrial,
  startSubscription,
  Abonnement
}

export interface pagination {
  count?: number;
  total_pages?: number;
  selected_region?: [string];
  total?: number;
  page?: number;
}

export interface fromSize {
  from?: number;
  to?: number;
}
export interface fromRoom {
  from?: number;
  to?: number;
}

export interface sizeOutsideArea {
  from?: number;
  to?: number;
}

export interface sizePlotArea {
  from?: number;
  to?: number;
}

export interface price {
  from?: number;
  to?: number;
}

export interface typeYearBuilt {
  from?: number;
  to?: number;
}

export interface populationGrowth {
  from?: number;
  to?: number;
}

export interface unemploymentRate {
  from?: number;
  to?: number;
}

export enum ObjectTypeOptionApartment {
  All = 'ALL',
  GROUND_FLOOR = 'GROUND_FLOOR',
  MAISONETTE = 'MAISONETTE',
  FLOOR_APARTMENT = 'FLOOR_APARTMENT',
  ROOF_STOREY = 'ROOF_STOREY',
  PENTHOUSE = 'PENTHOUSE',
  GARCONNIERE = 'GARCONNIERE',
  LOFT = 'LOFT'
}
export enum ObjectTypeOptionHouse {
  All = 'ALL',
  SEMIDETACHED_HOUSE = 'SEMIDETACHED_HOUSE',
  SINGLE_FAMILY_HOUSE = 'SINGLE_FAMILY_HOUSE',
  MULTI_FAMILY_HOUSE = 'MULTI_FAMILY_HOUSE',
  COUNTRY_HOUSE = 'COUNTRY_HOUSE',
  FARMHOUSE = 'FARMHOUSE'
}

export interface BargainsFilter {
  showFilter: boolean;
  isAuction: boolean;
  postal_code: [number];
  selected_region: [string];
  faiv: boolean;
  hasOutsideArea: boolean;
  sizeOutsideArea: sizeOutsideArea;
  createdAt: number;
  cherryScore: number;
  amElevator: boolean;
  rendite?: number;
  buyRent: BuyRent;
  objectType: ObjectType;
  objectTypeOption: ObjectTypeOptionApartment | ObjectTypeOptionHouse;
  region?: Array<{}>;
  typeYearBuilt: typeYearBuilt;
  fromSize: fromSize;
  sizePlotArea: sizePlotArea;
  fromRoom: fromRoom;
  populationGrowth: populationGrowth;
  unemploymentRate: unemploymentRate;
  condition: conditionItems;
  sellerPrivateInsertion: boolean;
  price: price;
  outdoorArea: boolean;
  exactAddressKnown: boolean;
  total?: number;
  count?: number;
  page?: number;
  size: number;
  loading: boolean;
  alleAnzeigen: [AlleAnzeigen];
  sort: Sort;
}
export interface BargainsList {
  image?: string;
  id?: string;
  sold?: boolean;
  type?: string;
  objectType?: string;
  sellingPrice?: number;
  ireenEstimate?: number;
  address?: { item1: string; item2: string };
  rooms?: number;
  livingSpace?: number;
  usableArea?: number;
}
export interface Recurring {
  interval_count?: string;
  interval?: number;
}
export enum login {
  login,
  register,
  update
}
export interface Subscription {
  login: login;
  product?: string;
  selected: boolean;
  id: string;
  productId?: string;
  interval?: string;
  intervalCount?: number;
  price?: string;
  recurring?: Recurring;
  unit_amount?: number;
  interval_count?: number;
}

export interface address {
  city?: string;
  state?: string;
  street?: string;
  zip_code?: number;
  zip?: number;
  streetNumber?: number;
}

export interface Area {
  effectiveArea?: number;
  gardenArea?: number;
  hasGarden?: boolean;
  hasTerrace?: boolean;
  livingArea?: number;
  numberOfAppartments?: number;
  numberOfBathrooms?: number;
  numberOfRooms?: number;
  numberOfTerrace?: number;
  primaryArea?: number;
  terraceArea?: number;
}
export interface cdf {
  x?: [number];
  y?: [number];
}
export interface Condition {
  age: string;
  energyCertification?: any;
  type: string;
  yearOfConstruction?: string;
  yearOfConstructionInteger?: number;
}

export interface Density {
  x?: [number];
  y?: [number];
}

export interface Description {
  descriptionNote?: string;
  title?: string;
}

export interface Localization {
  address: address;
  geo: Object;
  information: Object;
  locationPrecision: null;
}

export interface Details {
  object_id?: string;
  _id?: string;
  evaluation_id?: string;
  address?: address;
  address_known?: boolean;
  area?: Area;
  bargain_id?: string;
  price_density?: {
    pdf?: cdf;
    cdf?: Density;
  };
  price_q75?: number;
  cherry_score?: number;
  con_mean?: number;
  condition?: Condition;
  description?: Description;
  image_url?: string;
  living_area?: string;
  localization?: Localization;
  market_value_comparison?: number;
  number_of_rooms?: number;
  price?: number;
  price_per_square_meter?: number;
  price_return?: number;
  price_q25?: string;
  rent_per_square_meter?: number;
  price_pred?: number;
  rent_pred?: number;
  estate_type?: string;
  fitting?: any;
  numberOfWC?: number;
  furnished?: boolean;
  caller?: boolean;
  tags?: [string];
  sourceUrl?: string;
  priceInformation: any;
}
