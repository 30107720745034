import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { container } from 'tsyringe';
import type {
  EditEvaluationResponseSchema,
  GetEvaluationResponseSchema,
  Portfolio
} from '~/schema/api';
import { EvalutionType, ListingTypeTransferType } from '~/schema/api';
import type { CurrentEvaluation } from '~/entities/evaluation';
import { RefinedEvaluationService } from '~/services/refined-evaluation.service';

@Module({
  name: 'evaluations',
  stateFactory: true,
  namespaced: true
})
export default class EvaluationsSthasEvaluationsore extends VuexModule {
  // selected evaluation from the sidebar list
  // result of get request
  public selectedEvaluation: EditEvaluationResponseSchema | null = null;
  // list of evaluations result of get list request
  public evaluations: GetEvaluationResponseSchema[] = [];

  public portfolio: Portfolio | null = null;

  public risk: number | null = null;
  public plandValue: number | null = null;
  public plandPersentage: number | null = null;
  // current evaluation that is being created
  // result of post request and patch request
  public currentEvaluation: CurrentEvaluation = {
    id: null,
    listing: null,
    chartDetails: null,
    ireenEvaluation: null
  };

  public evalationType: EvalutionType = EvalutionType.all;
  public transferType: ListingTypeTransferType = ListingTypeTransferType.BUY;
  @Mutation
  setPortfolioTransferType(val: ListingTypeTransferType) {
    this.transferType = val;
  }

  @Mutation
  setRisck(value) {
    this.risk = value;
  }

  @Mutation
  setPlandValue(value) {
    this.plandValue = value;
  }

  @Mutation
  setPlandpersent(value) {
    this.plandPersentage = value;
  }

  @Mutation
  updateEvaluationWithSelectedEvaluation() {
    const id = this.selectedEvaluation?.id;
    const index = this.evaluations.indexOf(
      this.evaluations.find((item) => item.id === id)
    );
    this.evaluations[index] = this.selectedEvaluation;
  }

  @Mutation
  setEvalautionType(type: EvalutionType) {
    this.evalationType = type;
  }

  @Mutation
  setOneEvaluations(oneEvaluation: GetEvaluationResponseSchema) {
    this.evaluations = [oneEvaluation];
  }

  @Mutation
  setEvaluations(evaluations: GetEvaluationResponseSchema[]) {
    this.evaluations = evaluations && evaluations.reverse();
  }

  @Mutation
  setTransferType(val) {
    this.selectedEvaluation.listing?.type?.transferType = val;
  }

  @Mutation
  setSelectedEvaluation(evaluation: EditEvaluationResponseSchema) {
    this.selectedEvaluation = evaluation;

    container.resolve(RefinedEvaluationService).fillRefinedEvaluationStore();
  }

  compare(property) {
    var sortOrder = 1;
    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      /* next line works with strings and numbers,
       * and you may want to customize it to your needs
       */
      var result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }

  deCompare(property) {
    var sortOrder = 1;
    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      /* next line works with strings and numbers,
       * and you may want to customize it to your needs
       */
      var result =
        a[property] < b[property] ? 1 : a[property] > b[property] ? -1 : 0;
      return result * sortOrder;
    };
  }

  @Mutation
  sortEvaluationsList(type) {
    const items = this.evaluations.map((item) => ({
      id: item.id,
      address: `${
        item?.listing?.localization?.address?.street
          ? item.listing?.localization?.address.street
          : ''
      } ${
        item?.listing?.localization?.address?.streetNumber
          ? item.listing?.localization?.address.streetNumber
          : ''
      } ${
        item?.listing?.localization?.address?.zip
          ? item.listing?.localization?.address.zip
          : ''
      } ${
        item?.listing?.localization?.address?.municipality
          ? item?.listing?.localization?.address?.municipality
          : ''
      }`
    }));
    const copeyEvaluations = this.evaluations;
    let sortedItems;
    this.evaluations = [];
    if (type) {
      sortedItems = items.sort(function (a, b) {
        return a.address.localeCompare(b.address);
      });
    } else {
      sortedItems = items.sort(function (a, b) {
        return b.address.localeCompare(a.address);
      });
    }
    sortedItems.forEach((element) => {
      this.evaluations.push(
        copeyEvaluations.find((item) => item.id === element.id)
      );
    });
  }

  @Mutation
  removePortfolio(id: GetEvaluationResponseSchema['id']) {
    this.portfolio?.portfolio = this.portfolio?.portfolio.filter(
      (item) => item.id !== id
    );
  }

  @Mutation
  removeEvaluation(id: GetEvaluationResponseSchema['id']) {
    this.evaluations = this.evaluations.filter(
      (evaluation) => evaluation.id !== id
    );
  }

  @Mutation
  setCurrentEvaluation(evaluation: Partial<CurrentEvaluation>) {
    this.currentEvaluation.id = evaluation.id || null;
    this.currentEvaluation.listing = evaluation?.listing || null;
    this.currentEvaluation.chartDetails = evaluation.chartDetails || null;
    this.currentEvaluation.ireenEvaluation =
      evaluation?.ireenEvaluation || null;
  }

  @Mutation
  setPriceDetails(priceDetails: CurrentEvaluation['ireenEvaluation']) {
    this.currentEvaluation.ireenEvaluation = priceDetails || null;
  }

  @Mutation
  setChartDetails(chartDetails: CurrentEvaluation['chartDetails']) {
    this.currentEvaluation.chartDetails = chartDetails;
  }

  @Mutation
  resetSteps() {
    this.currentEvaluation.chartDetails = null;
  }

  @Mutation
  resetStore() {
    this.evaluations = [];
    this.currentEvaluation = {
      id: null,
      listing: null,
      chartDetails: null,
      ireenEvaluation: null
    };
  }

  @Mutation
  resetCurrentEvaluation() {
    this.currentEvaluation = {
      id: null,
      listing: null,
      chartDetails: null,
      ireenEvaluation: null
    };
  }

  get hasCurrentEvaluation() {
    return this.currentEvaluation.id !== null;
  }

  get hasSelectedEvaluation() {
    return this.selectedEvaluation !== null;
  }

  get isSelectedEvaluationComplete() {
    return (
      this.selectedEvaluation &&
      this.selectedEvaluation?.listing !== null &&
      this.selectedEvaluation?.density !== null &&
      this.selectedEvaluation?.ireenEvaluation !== null
    );
  }

  get hasEvaluations(): boolean {
    return this.evaluations?.length > 0;
  }

  get type() {
    const type = this.selectedEvaluation
      ? this.selectedEvaluation.listing?.type?.transferType ||
        ListingTypeTransferType.BUY
      : ListingTypeTransferType.BUY;
    return type.toLocaleLowerCase();
  }

  get selectedEvaluationPreferredPriceDetails() {
    return this.selectedEvaluation
      ? this.selectedEvaluation?.ireenEvaluation[this.type]?.refined
        ? this.selectedEvaluation?.ireenEvaluation[this.type]?.refined
        : this.selectedEvaluation?.ireenEvaluation[this.type]?.basic
      : {
          rangeMax: 0,
          rangeMean: 0,
          rangeMin: 0
        };
  }

  get selectedEvaluationHasDoneRefined() {
    return !!this.selectedEvaluation?.ireenEvaluation?.buy?.refined;
  }

  findEvaluationById(id: GetEvaluationResponseSchema['id']) {
    return this.evaluations.find((evaluation) => evaluation.id === id);
  }

  ///portfolio
  @Mutation
  setPortfolioList(portfolio: Portfolio) {
    this.portfolio = portfolio;
  }
}
