import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
import type { EditEvaluationRequestSchema } from '~/schema/api';
import {
  Accessibility,
  Age,
  BalconyTerraceOrientation,
  Bathroom,
  EditRefinedEvaluationRequestSchema,
  Facilities,
  FiringType,
  Floor,
  Furnishing,
  HeatingType,
  Kitchen,
  Lift,
  elevatorOptions,
  ParkingSpace,
  RoutersListingModelConditionType,
  RoutersListingModelConstructionType,
  View,
  MiscellaneousOptions,
  roofTypesSchema
} from '~/schema/api';
import { Debounce } from '~/decorators/debounce';
import {
  ConditionOptions,
  elevator,
  furnishing,
  caller,
  kind,
  BathroomOption,
  Miscellaneous,
  Construction,
  FiringTypeOptions,
  FloorTypeOptions,
  HeatingTypeOptions,
  IRefinedEvaluation,
  KitchenOptions,
  OrientationOptions,
  OutdoorAreasOptions,
  ParkingSpaceOptions,
  PolarOptions,
  ViewOptions,
  roofTypes
} from '~/entities/refined-evaluation';
import {
  ifArrayNotEmpty,
  ifArrayNotEmptyReturnArray,
  ifDefinedOtherwiseUndefined,
  switchCaseMap
} from '~/utils/utils';
import { reverseItems } from '~/utils/array';

type DependantFeature<
  K extends keyof IRefinedEvaluation = keyof IRefinedEvaluation
> = [K, IRefinedEvaluation[K] | void];

@Module({
  name: 'refinedEvaluation',
  stateFactory: true,
  namespaced: true,
  preserveState: false
})
export default class RefinedEvaluation extends VuexModule {
  step = 1;

  showForm = false;

  features: IRefinedEvaluation = {
    heatingDemand: undefined,
    accessibility: undefined,
    facilities: undefined,
    suitableForSharedApartment: undefined,
    heatingDemandClass: undefined,
    numberOfFloors: undefined,
    floor: undefined,
    constructionMethod: undefined,
    lift: undefined,
    hasGarden: undefined,
    hasBalcony: undefined,
    hasLoggia: undefined,
    hasTerrace: undefined,
    hasOutsideArea: undefined,
    isOwner: undefined,
    intendedSellingPrice: undefined,
    transactionPriceHistory: undefined,
    isRented: undefined,
    rentPrice: undefined,
    rentDate: undefined,
    fixedTermLease: undefined,
    fixedTermLeaseDate: undefined,
    heatingCosts: undefined,
    monthlyReserves: undefined,
    heatingTax: 20,
    heatingGross: undefined,
    operationalCosts: undefined,
    operationalTax: 10,
    operationalGross: undefined,
    miscCosts: undefined,
    additionalTax: 20,
    miscCostsGross: undefined,
    electricityCosts: undefined,
    electricityTax: 20,
    electricityGross: undefined,
    shapeOfRoof: undefined,
    cellar: undefined,
    cellarArea: undefined,
    bikeRoom: undefined,
    storeRoom: undefined,
    washingDryingRoom: undefined,
    isVacationProperty: undefined,
    apartmentOrientation: undefined,
    newBuilding: undefined,
    MonthlyRunningCosts: undefined,
    firstTimeUse: undefined,
    condition: undefined,
    miscellaneous: undefined,
    numberOfBathrooms: undefined,
    bathroomBathTube: undefined,
    kind: undefined,
    bathroomBidet: undefined,
    bathroomWindow: undefined,
    bathroomShower: undefined,
    numberOfToilets: undefined,
    guestToilet: undefined,
    bathroom: undefined,
    bathroomUrinal: undefined,
    numberOfBedrooms: undefined,
    kitchen: undefined,
    heatingType: undefined,
    firingType: undefined,
    floorType: undefined,
    sauna: undefined,
    hasCellar: undefined,
    fireplace: undefined,
    airConditioning: undefined,
    furnishing: undefined,
    outdoorAreas: undefined,
    numberOfBalconies: undefined,
    balconyArea: undefined,
    balconyOrientation: undefined,
    numberOfLoggia: undefined,
    loggiaArea: undefined,
    loggiaOrientation: undefined,
    terraceArea: undefined,
    numberOfTerrace: undefined,
    terraceOrientation: undefined,
    gardenArea: undefined,
    gardenOrientation: undefined,
    view: undefined,

    numberOfBasementGarageSpaces: undefined,
    numberOfCarportSpaces: undefined,
    numberOfDuplexSpaces: undefined,
    numberOfGarageSpaces: undefined,
    numberOfParkingDeckSpaces: undefined,
    numberOfParkingSpaces: undefined,
    handicappedAccessible: undefined,
    swimmingPool: undefined,
    winterGarden: undefined,
    shutter: undefined,
    gardenAvailable: undefined,
    balconyAvailable: undefined,
    loggiaAvailable: undefined,
    terrasseAvailable: undefined,
    parkingSpaceAvailable: undefined,
    basementGarage: undefined,
    carport: undefined,
    duplex: undefined,
    garage: undefined,
    parkingSpace: undefined,
    freeParkingSpace: undefined,
    parkingDeck: undefined
  };

  static dependantFeatureKeys: Partial<
    Record<keyof IRefinedEvaluation, DependantFeature>
  > = {
    balconyArea: ['outdoorAreas', OutdoorAreasOptions.BALCONY],
    balconyOrientation: ['outdoorAreas', OutdoorAreasOptions.BALCONY],
    numberOfBalconies: ['outdoorAreas', OutdoorAreasOptions.BALCONY],
    loggiaArea: ['outdoorAreas', OutdoorAreasOptions.LOGGIA],
    loggiaOrientation: ['outdoorAreas', OutdoorAreasOptions.LOGGIA],
    numberOfLoggia: ['outdoorAreas', OutdoorAreasOptions.LOGGIA],
    terraceArea: ['outdoorAreas', OutdoorAreasOptions.TERRACE],
    numberOfTerrace: ['outdoorAreas', OutdoorAreasOptions.TERRACE],
    terraceOrientation: ['outdoorAreas', OutdoorAreasOptions.TERRACE],
    gardenArea: ['outdoorAreas', OutdoorAreasOptions.GARDEN],
    gardenOrientation: ['outdoorAreas', OutdoorAreasOptions.GARDEN],
    numberOfParkingSpaces: ['parkingSpace', ParkingSpaceOptions.PARKING_SPACE],
    numberOfBasementGarageSpaces: [
      'parkingSpace',
      ParkingSpaceOptions.BASEMENT_GARAGE
    ],
    numberOfCarportSpaces: ['parkingSpace', ParkingSpaceOptions.CARPORT],
    numberOfDuplexSpaces: ['parkingSpace', ParkingSpaceOptions.DUPLEX],
    numberOfGarageSpaces: ['parkingSpace', ParkingSpaceOptions.GARAGE],
    numberOfParkingDeckSpaces: [
      'parkingSpace',
      ParkingSpaceOptions.PARKING_DECK
    ]
  };

  static maps = {
    age: [
      [PolarOptions.Yes, Age.NEWBUILDING],
      [PolarOptions.No, Age.OLDBUILDING]
    ] as [PolarOptions, Age][],
    type: [
      [
        ConditionOptions.FIRST_TIME_USE,
        RoutersListingModelConditionType.FIRST_TIME_USE
      ],
      [
        ConditionOptions.MODERNIZED,
        RoutersListingModelConditionType.MODERNIZED
      ],
      [
        ConditionOptions.FULLY_RENOVATED,
        RoutersListingModelConditionType.FULLY_RENOVATED
      ],
      [
        ConditionOptions.MINT_CONDITION,
        RoutersListingModelConditionType.MINT_CONDITION
      ],
      [
        ConditionOptions.NEED_OF_RENOVATION,
        RoutersListingModelConditionType.NEED_OF_RENOVATION
      ],
      [
        ConditionOptions.REFURBISHED,
        RoutersListingModelConditionType.REFURBISHED
      ],
      [ConditionOptions.WELL_KEPT, RoutersListingModelConditionType.WELL_KEPT],
      [ConditionOptions.UNKNOWN, null]
    ] as [ConditionOptions, RoutersListingModelConditionType][],
    constructionMethod: [
      [Construction.MASSIVE, RoutersListingModelConstructionType.MASSIVE],
      [
        Construction.PREFABRICATED,
        RoutersListingModelConstructionType.PREFABRICATED
      ],
      [Construction.WOOD, RoutersListingModelConstructionType.WOOD],
      [Construction.UNKNOWN, null]
    ] as [Construction, RoutersListingModelConstructionType][],
    furnishing: [
      [furnishing.FULL, Furnishing.FULL],
      [furnishing.PART, Furnishing.PART],
      [furnishing.NULL, null],
      [furnishing.FURNISHED, Furnishing.FURNISHED]
    ] as [furnishing, Furnishing][],
    handicappedAccessible: [
      [PolarOptions.Yes, [Accessibility.HANDICAPPED_ACCESSIBLE]],
      [PolarOptions.No, []]
    ] as [PolarOptions, Accessibility[]][],
    shapeOfRoof: [
      [roofTypes.GABLED_ROOF, roofTypesSchema.GABLED_ROOF],
      [roofTypes.FLACHDACH, roofTypesSchema.FLACHDACH],
      [roofTypes.PENT_ROOF, roofTypesSchema.PENT_ROOF],
      [roofTypes.MANSARD_ROOF, roofTypesSchema.MANSARD_ROOF],
      [roofTypes.HIP_ROOF, roofTypesSchema.HIP_ROOF],
      [roofTypes.HALF_HIPPED_ROOF, roofTypesSchema.HALF_HIPPED_ROOF],
      [roofTypes.PYRAMID_ROOF, roofTypesSchema.PYRAMID_ROOF]
    ] as [roofTypes, roofTypesSchema][],
    lift: [
      [elevator.PASSENGER_ELEVATOR, elevatorOptions.PASSENGER_ELEVATOR],
      [elevator.FREIGHT_ELEVATOR, elevatorOptions.FREIGHT_ELEVATOR]
    ] as [elevator, elevatorOptions][],
    gardenAvailable: [
      [PolarOptions.Yes, [Lift.LIFT]],
      [PolarOptions.Yes, []]
    ] as [PolarOptions, Lift[]][],
    balconyAvailable: [
      [PolarOptions.Yes, [Lift.LIFT]],
      [PolarOptions.Yes, []]
    ] as [PolarOptions, Lift[]][],
    loggiaAvailable: [
      [PolarOptions.Yes, [Lift.LIFT]],
      [PolarOptions.Yes, []]
    ] as [PolarOptions, Lift[]][],
    terrasseAvailable: [
      [PolarOptions.Yes, [Lift.LIFT]],
      [PolarOptions.Yes, []]
    ] as [PolarOptions, Lift[]][],
    parkingSpaceAvailable: [
      [PolarOptions.Yes, [Lift.LIFT]],
      [PolarOptions.Yes, []]
    ] as [PolarOptions, Lift[]][],
    basementGarage: [
      [PolarOptions.Yes, [Lift.LIFT]],
      [PolarOptions.Yes, []]
    ] as [PolarOptions, Lift[]][],
    floorType: [
      [FloorTypeOptions.CARPET, Floor.CARPET],
      [FloorTypeOptions.BOARDS, Floor.BOARDS],
      [FloorTypeOptions.CARPET, Floor.CARPET],
      [FloorTypeOptions.GRANITE, Floor.GRANITE],
      [FloorTypeOptions.LAMINATE, Floor.LAMINATE],
      [FloorTypeOptions.LINOLEUM, Floor.LINOLEUM],
      [FloorTypeOptions.MARBLE, Floor.MARBLE],
      [FloorTypeOptions.PARQUET, Floor.PARQUET],
      [FloorTypeOptions.PLASTIC, Floor.PLASTIC],
      [FloorTypeOptions.SCREED, Floor.SCREED],
      [FloorTypeOptions.STONE, Floor.STONE],
      [FloorTypeOptions.TERRACOTTA, Floor.TERRACOTTA],
      [FloorTypeOptions.TILES, Floor.TILES]
    ] as [FloorTypeOptions, Floor][],
    balconyOrientation: [
      [OrientationOptions.NORTH, BalconyTerraceOrientation.NORTH],
      [OrientationOptions.NORTH_EAST, BalconyTerraceOrientation.NORTH_EAST],
      [OrientationOptions.NORTH_WEST, BalconyTerraceOrientation.NORTH_WEST],
      [OrientationOptions.SOUTH, BalconyTerraceOrientation.SOUTH],
      [OrientationOptions.SOUTH_EAST, BalconyTerraceOrientation.SOUTH_EAST],
      [OrientationOptions.SOUTH_WEST, BalconyTerraceOrientation.SOUTH_WEST],
      [OrientationOptions.EAST, BalconyTerraceOrientation.EAST],
      [OrientationOptions.WEST, BalconyTerraceOrientation.WEST]
    ] as [OrientationOptions, BalconyTerraceOrientation][],
    terraceOrientation: [
      [OrientationOptions.NORTH, BalconyTerraceOrientation.NORTH],
      [OrientationOptions.NORTH_EAST, BalconyTerraceOrientation.NORTH_EAST],
      [OrientationOptions.NORTH_WEST, BalconyTerraceOrientation.NORTH_WEST],
      [OrientationOptions.SOUTH, BalconyTerraceOrientation.SOUTH],
      [OrientationOptions.SOUTH_EAST, BalconyTerraceOrientation.SOUTH_EAST],
      [OrientationOptions.SOUTH_WEST, BalconyTerraceOrientation.SOUTH_WEST],
      [OrientationOptions.EAST, BalconyTerraceOrientation.EAST],
      [OrientationOptions.WEST, BalconyTerraceOrientation.WEST]
    ] as [OrientationOptions, BalconyTerraceOrientation][],
    loggiaOrientation: [
      [OrientationOptions.NORTH, BalconyTerraceOrientation.NORTH],
      [OrientationOptions.NORTH_EAST, BalconyTerraceOrientation.NORTH_EAST],
      [OrientationOptions.NORTH_WEST, BalconyTerraceOrientation.NORTH_WEST],
      [OrientationOptions.SOUTH, BalconyTerraceOrientation.SOUTH],
      [OrientationOptions.SOUTH_EAST, BalconyTerraceOrientation.SOUTH_EAST],
      [OrientationOptions.SOUTH_WEST, BalconyTerraceOrientation.SOUTH_WEST],
      [OrientationOptions.EAST, BalconyTerraceOrientation.EAST],
      [OrientationOptions.WEST, BalconyTerraceOrientation.WEST]
    ] as [OrientationOptions, BalconyTerraceOrientation][],
    firingType: [
      [FiringTypeOptions.AIR_HEAT, FiringType.AIR_HEAT],
      [FiringTypeOptions.ALTERNATIVE, FiringType.ALTERNATIVE],
      [FiringTypeOptions.BLOCK_HEATING, FiringType.BLOCK_HEATING],
      [FiringTypeOptions.COAL, FiringType.COAL],
      [FiringTypeOptions.DISTRICT_HEATING, FiringType.DISTRICT_HEATING],
      [FiringTypeOptions.ELECTRICITY, FiringType.ELECTRICITY],
      [FiringTypeOptions.GAS, FiringType.GAS],
      [FiringTypeOptions.GEOTHERMAL, FiringType.GEOTHERMAL],
      [FiringTypeOptions.OIL, FiringType.OIL],
      [FiringTypeOptions.PELLET_HEATING, FiringType.PELLET_HEATING],
      [FiringTypeOptions.SOLAR_HEATING, FiringType.SOLAR_HEATING],
      [
        FiringTypeOptions.WATER_ELECTRICITY_HEATING,
        FiringType.WATER_ELECTRICITY_HEATING
      ]
    ] as [FiringTypeOptions, FiringType][],
    heatingType: [
      [HeatingTypeOptions.CENTRAL_HEATING, HeatingType.CENTRAL_HEATING],
      [HeatingTypeOptions.DISTRICT_HEATING, HeatingType.DISTRICT_HEATING],
      [HeatingTypeOptions.FLOOR_HEATING, HeatingType.FLOOR_HEATING],
      [
        HeatingTypeOptions.SELF_CONTAINED_CENTRAL_HEATING,
        HeatingType.SELF_CONTAINED_CENTRAL_HEATING
      ],
      [HeatingTypeOptions.STOVE_HEATING, HeatingType.STOVE_HEATING]
    ] as [HeatingTypeOptions, HeatingType][],

    kitchen: [
      [KitchenOptions.OPEN_KITCHEN, Kitchen.OPEN_KITCHEN],
      [KitchenOptions.FITTED_KITCHEN, Kitchen.FITTED_KITCHEN],
      [KitchenOptions.KITCHENETTE, Kitchen.KITCHENETTE],
      [KitchenOptions.NO_KITCHEN, null]
    ] as [KitchenOptions, Kitchen | undefined][],
    // parkingSpace: [
    //   [ParkingSpaceOptions.BASEMENT_GARAGE, ParkingSpace.BASEMENT_GARAGE],
    //   [ParkingSpaceOptions.CARPORT, ParkingSpace.CARPORT],
    //   [ParkingSpaceOptions.DUPLEX, ParkingSpace.DUPLEX],
    //   [ParkingSpaceOptions.GARAGE, ParkingSpace.GARAGE],
    //   [ParkingSpaceOptions.PARKING_SPACE, ParkingSpace.PARKING_SPACE],
    //   [ParkingSpaceOptions.PARKING_DECK, ParkingSpace.PARKING_DECK]
    // ] as [ParkingSpaceOptions, ParkingSpace][],
    accessibility: [
      [Miscellaneous.BARRIER_FREE, MiscellaneousOptions.BARRIER_FREE]
    ] as [Miscellaneous, MiscellaneousOptions][],
    facilities: [
      [
        Miscellaneous.BICYCLE_STORAGE_ROOM,
        MiscellaneousOptions.BICYCLE_STORAGE_ROOM
      ],
      [Miscellaneous.WASHROOM, MiscellaneousOptions.WASHROOM],
      [Miscellaneous.GUEST_TOILET, MiscellaneousOptions.GUEST_TOILET],
      [Miscellaneous.SAUNA, MiscellaneousOptions.SAUNA],
      [Miscellaneous.POOL, MiscellaneousOptions.POOL],
      [Miscellaneous.AIR_CONDITIONER, MiscellaneousOptions.AIR_CONDITIONER],
      [Miscellaneous.CONSERVATORY, MiscellaneousOptions.CONSERVATORY],
      [Miscellaneous.CHIMNEY, MiscellaneousOptions.CHIMNEY],
      [Miscellaneous.BARRIER_FREE, MiscellaneousOptions.BARRIER_FREE],
      [Miscellaneous.SHUTTERS, MiscellaneousOptions.SHUTTERS],
      [
        Miscellaneous.SUITABLE_FOR_SHARED_FLATS,
        MiscellaneousOptions.SUITABLE_FOR_SHARED_FLATS
      ]
    ] as [Miscellaneous, MiscellaneousOptions][],
    bathroom: [
      [BathroomOption.BATHTUBE, Bathroom.BATHTUBE],
      [BathroomOption.SHOWER, Bathroom.SHOWER],
      [BathroomOption.window, Bathroom.WINDOW],
      [BathroomOption.BIDET, Bathroom.BIDET],
      [BathroomOption.URINAL, Bathroom.URINAL]
    ] as [BathroomOption, Bathroom][],
    view: [
      [ViewOptions.AFAR, View.AFAR],
      [ViewOptions.SEE, View.LAKE],
      [ViewOptions.MOUNTAINS, View.MOUNTAINS]
    ] as [ViewOptions, View][]
  };

  static mapToSchema(
    features: IRefinedEvaluation
  ): EditRefinedEvaluationRequestSchema {
    return {
      area: {
        balconyArea: features.hasBalcony ? features.balconyArea : null,
        gardenArea: features.hasGarden ? features.gardenArea : null,
        loggiaArea: features.hasLoggia ? features.loggiaArea : null,
        terraceArea: features.hasTerrace ? features.terraceArea : null,
        numberOfTerrace: features.hasTerrace ? features.numberOfTerrace : null,
        hasOutsideArea: features.hasOutsideArea
          ? features.hasOutsideArea
          : null,
        hasCellar: ifDefinedOtherwiseUndefined(features.hasCellar, () => {
          if (features.hasCellar === caller.yes) {
            return true;
          } else if (features.hasCellar === caller.no) {
            return false;
          } else {
            return null;
          }
        }),
        numberOfBalconies: features.numberOfBalconies,
        numberOfLoggia: features.numberOfLoggia,
        numberOfTerrace: features.numberOfTerrace,
        numberOfBathrooms: features.numberOfBathrooms,
        numberOfBedrooms: features.numberOfBedrooms,
        numberOfToilets: features.numberOfToilets,
        hasBalcony: ifDefinedOtherwiseUndefined(features.hasBalcony, () =>
          features.hasBalcony ? true : false
        ),
        hasGarden: ifDefinedOtherwiseUndefined(features.hasGarden, () =>
          features.hasGarden ? true : false
        ),
        hasLoggia: ifDefinedOtherwiseUndefined(features.hasLoggia, () =>
          features.hasLoggia ? true : false
        ),
        hasTerrace: ifDefinedOtherwiseUndefined(features.hasTerrace, () =>
          features.hasTerrace ? true : false
        ),
        hasOutsideArea: ifDefinedOtherwiseUndefined(
          features.hasOutsideArea,
          () => (features.hasOutsideArea ? true : false)
        )
      },
      type: {
        propertyTypeAttribute: {
          isVacationProperty: ifDefinedOtherwiseUndefined(
            features.isVacationProperty,
            () => features.isVacationProperty === PolarOptions.Yes
          )
        }
      },
      fitting: {
        furnishing: ifDefinedOtherwiseUndefined(features.furnishing, () =>
          switchCaseMap(features.furnishing, this.maps.furnishing)
        ),
        // accessibility: ifDefinedOtherwiseUndefined(
        //   features.handicappedAccessible,
        //   () =>
        //     switchCaseMap(
        //       features.handicappedAccessible,
        //       this.maps.handicappedAccessible
        //     )
        // ),
        floor: ifDefinedOtherwiseUndefined(
          features.floorType,
          () =>
            features.floorType!.map((floorType) =>
              switchCaseMap(floorType, this.maps.floorType)
            ) as Floor[]
        ),

        suitableForSharedApartment: ifDefinedOtherwiseUndefined(
          features.suitableForSharedApartment,
          () => features.suitableForSharedApartment
        ),

        balconyOrientation: ifDefinedOtherwiseUndefined(
          features.balconyOrientation,
          () =>
            features.balconyOrientation?.map((orientation) =>
              switchCaseMap(orientation, this.maps.balconyOrientation)
            ) as BalconyTerraceOrientation[]
        ),

        orientation: ifDefinedOtherwiseUndefined(
          features.apartmentOrientation,
          () =>
            features.apartmentOrientation?.map((orientation) =>
              switchCaseMap(orientation, this.maps.balconyOrientation)
            ) as BalconyTerraceOrientation[]
        ),

        loggiaOrientation: ifDefinedOtherwiseUndefined(
          features.loggiaOrientation,
          () =>
            features.loggiaOrientation?.map((orientation) =>
              switchCaseMap(orientation, this.maps.balconyOrientation)
            ) as BalconyTerraceOrientation[]
        ),

        terraceOrientation: ifDefinedOtherwiseUndefined(
          features.terraceOrientation,
          () =>
            features.terraceOrientation?.map((orientation) =>
              switchCaseMap(orientation, this.maps.balconyOrientation)
            ) as BalconyTerraceOrientation[]
        ),
        parking: {
          basementGarage: {
            inPlace:
              features.basementGarage && features.parkingSpaceAvailable
                ? true
                : features.basementGarage === PolarOptions.No
                ? false
                : null,
            count:
              features.basementGarage && features.parkingSpaceAvailable
                ? features.numberOfBasementGarageSpaces
                : null
          },
          carport: {
            inPlace:
              features.carport && features.parkingSpaceAvailable
                ? true
                : features.carport === PolarOptions.No
                ? false
                : null,
            count: features.carport ? features.numberOfCarportSpaces : null
          },
          duplex: {
            inPlace:
              features.duplex && features.parkingSpaceAvailable
                ? true
                : features.duplex === PolarOptions.No
                ? false
                : null,
            count:
              features.duplex && features.parkingSpaceAvailable
                ? features.numberOfDuplexSpaces
                : null
          },
          garage: {
            inPlace:
              features.garage && features.parkingSpaceAvailable
                ? true
                : features.garage === PolarOptions.No
                ? false
                : null,
            count:
              features.garage && features.parkingSpaceAvailable
                ? features.numberOfGarageSpaces
                : null
          },
          unknown: {
            inPlace:
              features.parkingDeck && features.parkingSpaceAvailable
                ? true
                : features.parkingDeck === PolarOptions.No
                ? false
                : null,
            count:
              features.parkingDeck && features.parkingSpaceAvailable
                ? features.numberOfParkingDeckSpaces
                : null
          },
          parkingSpace: {
            inPlace:
              features.parkingSpace && features.parkingSpaceAvailable
                ? true
                : features.parkingSpace === PolarOptions.No
                ? false
                : null,
            count:
              features.parkingSpace && features.parkingSpaceAvailable
                ? features.numberOfParkingSpaces
                : null
          }
        },

        // parkingSpaceGarageNumber: features.numberOfGarageSpaces,

        // parkingSpaceOutsideNumber: features.numberOfParkingSpaces,

        // parkingSpaceBasementGarageNumber: features.numberOfBasementGarageSpaces,

        // parkingSpaceCarportNumber: features.numberOfCarportSpaces,

        // parkingSpaceDuplexNumber: features.numberOfDuplexSpaces,

        // parkingSpaceParkingDeckNumber: features.numberOfParkingDeckSpaces,

        // parkingSpace: ifDefinedOtherwiseUndefined(
        //   features.parkingSpace,
        //   () =>
        //     features.parkingSpace?.map((parkingSpace) =>
        //       switchCaseMap(parkingSpace, this.maps.parkingSpace)
        //     ) as ParkingSpace[]
        // ),

        shapeOfRoof: ifDefinedOtherwiseUndefined(
          features.shapeOfRoof,
          () =>
            features.shapeOfRoof?.map((shapeOfRoof) =>
              switchCaseMap(shapeOfRoof, this.maps.shapeOfRoof)
            ) as roofTypesSchema[]
        ),
        lift: ifDefinedOtherwiseUndefined(
          features.lift,
          () =>
            features.lift?.map((lift) =>
              switchCaseMap(lift, this.maps.lift)
            ) as elevatorOptions[]
        ),

        heatingType: ifDefinedOtherwiseUndefined(
          features.heatingType,
          () =>
            features.heatingType?.map((heatingType) =>
              switchCaseMap(heatingType, this.maps.heatingType)
            ) as HeatingType[]
        ),
        view: ifDefinedOtherwiseUndefined(
          features.view,
          () =>
            features.view?.map((view) =>
              switchCaseMap(view, this.maps.view)
            ) as View[]
        ),
        constructionMethod: ifDefinedOtherwiseUndefined(
          features.constructionMethod,
          () =>
            features.constructionMethod?.map((constructionMethod) =>
              switchCaseMap(constructionMethod, this.maps.constructionMethod)
            ) as RoutersListingModelConstructionType[]
        ),
        bathroom: ifDefinedOtherwiseUndefined(
          features.bathroom,
          () =>
            features.bathroom?.map((bathroom) =>
              switchCaseMap(bathroom, this.maps.bathroom)
            ) as Bathroom[]
        ),
        firingType: ifDefinedOtherwiseUndefined(
          features.firingType,
          () =>
            features.firingType?.map((firingType) =>
              switchCaseMap(firingType, this.maps.firingType)
            ) as FiringType[]
        ),
        kitchen: ifDefinedOtherwiseUndefined(
          features.kitchen,
          () =>
            features.kitchen?.map((kitchen) =>
              switchCaseMap(kitchen, this.maps.kitchen)
            ) as Kitchen[]
        ),
        accessibility: ifDefinedOtherwiseUndefined(
          features.accessibility,
          () =>
            features.accessibility?.map((accessibility) =>
              switchCaseMap(accessibility, this.maps.accessibility)
            ) as MiscellaneousOptions[]
        ),
        facilities: ifDefinedOtherwiseUndefined(
          features.facilities,
          () =>
            features.facilities?.map((facilities) =>
              switchCaseMap(facilities, this.maps.facilities)
            ) as MiscellaneousOptions[]
        )
      },
      // parking: {
      //   basementGarage: {
      //     inPlace: features.basementGarage ? true : false,
      //     count: features.basementGarage
      //       ? features.numberOfBasementGarageSpaces
      //       : null
      //   },
      //   carport: {
      //     inPlace: features.carport ? true : false,
      //     count: features.carport ? features.numberOfCarportSpaces : null
      //   },
      //   duplex: {
      //     inPlace: features.duplex ? true : false,
      //     count: features.duplex ? features.numberOfDuplexSpaces : null
      //   },
      //   garage: {
      //     inPlace: features.garage ? true : false,
      //     count: features.garage ? features.numberOfGarageSpaces : null
      //   },
      //   unknown: {
      //     inPlace: features.parkingDeck ? true : false,
      //     count: features.parkingDeck
      //       ? features.numberOfParkingDeckSpaces
      //       : null
      //   },
      //   parkingSpace: {
      //     inPlace: features.parkingSpace ? true : false,
      //     count: features.parkingSpace ? features.numberOfParkingSpaces : null
      //   }
      // },
      condition: {
        energyCertification: {
          heatingDemand: features.heatingDemand,
          heatingDemandClass: features.heatingDemandClass
        },

        age: ifDefinedOtherwiseUndefined(features.newBuilding, () =>
          switchCaseMap(features.newBuilding, this.maps.age)
        ),

        type: ifDefinedOtherwiseUndefined(features.condition, () =>
          switchCaseMap(features.condition, this.maps?.type)
        )
      },

      priceInformation: {
        costs: {
          heatingCosts: {
            net: features.heatingGross
              ? this.calculateNetCosts(features.heatingCosts, 20)
              : features.heatingCosts,
            tax: this.calculateTaxCosts(
              !!features.heatingGross,
              features.heatingCosts,
              20
            ),
            showGross: features.heatingGross
          },
          operationalCosts: {
            net: features.operationalGross
              ? this.calculateNetCosts(
                  features.operationalCosts,
                  features.operationalTax
                )
              : features.operationalCosts,
            tax: this.calculateTaxCosts(
              !!features.operationalGross,
              features.operationalCosts,
              features.operationalTax
            ),
            showGross: features.operationalGross
          },
          electricityCosts: {
            net: features.electricityGross
              ? this.calculateNetCosts(features.electricityCosts, 20)
              : features.electricityCosts,
            tax: this.calculateTaxCosts(
              !!features.electricityGross,
              features.electricityCosts,
              20
            ),
            showGross: features.electricityGross
          },
          miscCosts: {
            net: features.miscCostsGross
              ? this.calculateNetCosts(features.miscCosts, 20)
              : features.miscCosts,
            tax: this.calculateTaxCosts(
              !!features.miscCostsGross,
              features.miscCosts,
              20
            ),
            showGross: features.miscCostsGross
          },
          monthlyCosts: {
            total: features.MonthlyRunningCosts
              ? features.MonthlyRunningCosts
              : null
          }
        },
        IREENUser: {
          leaseholdTerm: ifDefinedOtherwiseUndefined(
            features.fixedTermLease,
            () =>
              switchCaseMap(features.fixedTermLease, [
                [PolarOptions.Yes, true],
                [PolarOptions.No, false]
              ])
          ),
          leaseholdTermEnd: features.fixedTermLeaseDate,
          currentMainRent: features.rentPrice,
          currentRentContractStart: features.rentDate,
          intendedSellingPrice: features.intendedSellingPrice,
          transactionPriceHistory: ifDefinedOtherwiseUndefined(
            features.transactionPriceHistory,
            () =>
              ifArrayNotEmptyReturnArray(
                features.transactionPriceHistory?.filter(
                  (item) => item.price && item.date
                ) || []
              )
          )
        },
        prices: {
          monthlyReserves: features.monthlyReserves
        }
      },
      localization: {
        information: {
          numberOfFloors: features.numberOfFloors,
          floor: features.floor
        }
      },
      meta: {
        IREENUser: {
          isOwner: ifDefinedOtherwiseUndefined(features.isOwner, () =>
            switchCaseMap(features.isOwner, [
              [PolarOptions.Yes, true],
              [PolarOptions.No, false]
            ])
          )
        }
      },
      object: {
        isRentedLimited: ifDefinedOtherwiseUndefined(features.kind, () => {
          return features?.kind === kind.LIMITED;
        }),
        isRentedUnlimited: ifDefinedOtherwiseUndefined(features.kind, () => {
          return features?.kind === kind.INDEFINITE;
        }),
        isRented: ifDefinedOtherwiseUndefined(features.kind, () => {
          return features?.kind == kind.UNKNOWN;
        })
      }
    };
  }

  static mapToLocalState(
    schema: EditRefinedEvaluationRequestSchema
  ): IRefinedEvaluation {
    return {
      estateType: schema.type?.estateType,
      estateDetailType: schema.type?.estateDetailType,
      yearOfConstructionInteger: schema.condition?.yearOfConstructionInteger,
      heatingDemand: schema.condition?.energyCertification?.heatingDemand,

      heatingDemandClass:
        schema.condition?.energyCertification?.heatingDemandClass,
      numberOfFloors: schema.localization?.information?.numberOfFloors,
      floor: schema.localization?.information?.floor,
      lift: ifDefinedOtherwiseUndefined(
        schema.fitting?.lift,
        () =>
          schema.fitting?.lift
            ?.map((lift) =>
              switchCaseMap(
                lift,
                reverseItems(this.maps.lift) as [elevatorOptions, elevator][]
              )
            )
            .filter((value) => value != null) as elevator[]
      ),
      cellar: ifDefinedOtherwiseUndefined(schema.fitting?.facilities, () =>
        schema.fitting?.facilities?.includes(Facilities.CELLAR)
          ? PolarOptions.Yes
          : PolarOptions.No
      ),
      hasCellar: ifDefinedOtherwiseUndefined(schema.area?.hasCellar, () =>
        schema.area?.hasCellar ? caller.yes : caller.no
      ),
      bikeRoom: ifDefinedOtherwiseUndefined(schema.fitting?.facilities, () =>
        schema.fitting?.facilities?.includes(Facilities.BIKE_ROOM)
          ? PolarOptions.Yes
          : PolarOptions.No
      ),
      storeRoom: ifDefinedOtherwiseUndefined(schema.fitting?.facilities, () =>
        schema.fitting?.facilities?.includes(Facilities.STOREROOM)
          ? PolarOptions.Yes
          : PolarOptions.No
      ),
      washingDryingRoom: ifDefinedOtherwiseUndefined(
        schema.fitting?.facilities,
        () =>
          schema.fitting?.facilities?.includes(Facilities.WASHING_DRYING_ROOM)
            ? PolarOptions.Yes
            : PolarOptions.No
      ),
      suitableForSharedApartment: ifDefinedOtherwiseUndefined(
        schema.fitting?.suitableForSharedApartment,
        () => schema.fitting?.suitableForSharedApartment
      ),
      isVacationProperty: ifDefinedOtherwiseUndefined(
        schema.type?.propertyTypeAttribute?.isVacationProperty,
        () =>
          schema.type?.propertyTypeAttribute?.isVacationProperty
            ? PolarOptions.Yes
            : PolarOptions.No
      ),
      monthlyReserves: schema.priceInformation?.prices?.monthlyReserves,
      heatingCosts: schema.priceInformation?.costs?.heatingCosts?.showGross
        ? this.calculateGrossCosts(
            schema.priceInformation?.costs?.heatingCosts?.net,
            schema.priceInformation?.costs?.heatingCosts?.tax
          )
        : schema.priceInformation?.costs?.heatingCosts?.net,
      heatingGross: schema.priceInformation?.costs?.heatingCosts?.showGross,
      heatingTax:
        this.calculateTaxRate(
          schema.priceInformation?.costs?.heatingCosts?.net,
          schema.priceInformation?.costs?.heatingCosts?.tax
        ) || 20,
      operationalCosts: schema.priceInformation?.costs?.operationalCosts
        ?.showGross
        ? this.calculateGrossCosts(
            schema.priceInformation?.costs?.operationalCosts?.net,
            schema.priceInformation?.costs?.operationalCosts?.tax
          )
        : schema.priceInformation?.costs?.operationalCosts?.net,
      operationalGross:
        schema.priceInformation?.costs?.operationalCosts?.showGross,
      operationalTax:
        this.calculateTaxRate(
          schema.priceInformation?.costs?.operationalCosts?.net,
          schema.priceInformation?.costs?.operationalCosts?.tax
        ) || 10,
      electricityCosts: schema.priceInformation?.costs?.electricityCosts
        ?.showGross
        ? this.calculateGrossCosts(
            schema.priceInformation?.costs?.electricityCosts?.net,
            schema.priceInformation?.costs?.electricityCosts?.tax
          )
        : schema.priceInformation?.costs?.electricityCosts?.net,
      electricityGross:
        schema.priceInformation?.costs?.electricityCosts?.showGross,
      electricityTax:
        this.calculateTaxRate(
          schema.priceInformation?.costs?.electricityCosts?.net,
          schema.priceInformation?.costs?.electricityCosts?.tax
        ) || 20,
      miscCosts: schema.priceInformation?.costs?.miscCosts?.showGross
        ? this.calculateGrossCosts(
            schema.priceInformation?.costs?.miscCosts?.net,
            schema.priceInformation?.costs?.miscCosts?.tax
          )
        : schema.priceInformation?.costs?.miscCosts?.net,
      MonthlyRunningCosts: schema.priceInformation?.costs?.monthlyCosts?.total,
      additionalGross: schema.priceInformation?.costs?.miscCosts?.showGross,
      additionalTax:
        this.calculateTaxRate(
          schema.priceInformation?.costs?.miscCosts?.net,
          schema.priceInformation?.costs?.miscCosts?.tax
        ) || 20,
      newBuilding: ifDefinedOtherwiseUndefined(schema.condition?.age, () =>
        schema.condition?.age === Age.NEWBUILDING
          ? PolarOptions.Yes
          : PolarOptions.No
      ),
      // firstTimeUse: ,
      condition: ifDefinedOtherwiseUndefined(schema.condition?.type, () =>
        switchCaseMap(
          schema.condition?.type,
          reverseItems(this.maps?.type) as [
            RoutersListingModelConditionType,
            ConditionOptions
          ][]
        )
      ),
      fitting: {
        shapeOfRoof: ifDefinedOtherwiseUndefined(
          schema.fitting?.shapeOfRoof,
          () =>
            switchCaseMap(
              schema.fitting?.shapeOfRoof,
              reverseItems(this.maps?.shapeOfRoof) as [
                roofTypesSchema,
                roofTypes
              ][]
            )
        )
      },
      numberOfBathrooms: schema.area?.numberOfBathrooms,
      bathroomBathTube: ifDefinedOtherwiseUndefined(
        schema.fitting?.bathroom,
        () =>
          schema.fitting?.bathroom?.includes(Bathroom.BATHTUBE)
            ? PolarOptions.Yes
            : PolarOptions.No
      ),
      bathroomBidet: ifDefinedOtherwiseUndefined(schema.fitting?.bathroom, () =>
        schema.fitting?.bathroom?.includes(Bathroom.BIDET)
          ? PolarOptions.Yes
          : PolarOptions.No
      ),
      bathroomWindow: ifDefinedOtherwiseUndefined(
        schema.fitting?.bathroom,
        () =>
          schema.fitting?.bathroom?.includes(Bathroom.WINDOW)
            ? PolarOptions.Yes
            : PolarOptions.No
      ),
      bathroomShower: ifDefinedOtherwiseUndefined(
        schema.fitting?.bathroom,
        () =>
          schema.fitting?.bathroom?.includes(Bathroom.SHOWER)
            ? PolarOptions.Yes
            : PolarOptions.No
      ),
      numberOfToilets: schema.area?.numberOfToilets,
      guestToilet: ifDefinedOtherwiseUndefined(schema.fitting?.facilities, () =>
        schema.fitting?.facilities?.includes(Facilities.GUEST_TOILET)
          ? PolarOptions.Yes
          : PolarOptions.No
      ),
      bathroomUrinal: ifDefinedOtherwiseUndefined(
        schema.fitting?.bathroom,
        () =>
          schema.fitting?.bathroom?.includes(Bathroom.URINAL)
            ? PolarOptions.Yes
            : PolarOptions.No
      ),
      numberOfBedrooms: schema.area?.numberOfBedrooms,

      floorType: ifDefinedOtherwiseUndefined(schema.fitting?.floor, () =>
        ifArrayNotEmpty(
          schema.fitting?.floor
            ?.map((floor) =>
              switchCaseMap(
                floor,
                reverseItems(this.maps.floorType) as [Floor, FloorTypeOptions][]
              )
            )
            .filter((f) => f !== undefined) as FloorTypeOptions[]
        )
      ),
      sauna: ifDefinedOtherwiseUndefined(schema.fitting?.facilities, () =>
        schema.fitting?.facilities?.includes(Facilities.SAUNA)
          ? PolarOptions.Yes
          : PolarOptions.No
      ),
      fireplace: ifDefinedOtherwiseUndefined(schema.fitting?.facilities, () =>
        schema.fitting?.facilities?.includes(Facilities.FIREPLACE)
          ? PolarOptions.Yes
          : PolarOptions.No
      ),
      airConditioning: ifDefinedOtherwiseUndefined(
        schema.fitting?.facilities,
        () =>
          schema.fitting?.facilities?.includes(Facilities.AIR_CONDITIONING)
            ? PolarOptions.Yes
            : PolarOptions.No
      ),

      outdoorAreas: ifArrayNotEmpty(
        [
          schema.area?.hasBalcony ? OutdoorAreasOptions.BALCONY : undefined,
          schema.area?.hasTerrace ? OutdoorAreasOptions.TERRACE : undefined,

          schema.area?.hasLoggia ? OutdoorAreasOptions.LOGGIA : undefined
        ].filter((f) => f !== undefined) as OutdoorAreasOptions[]
      ),
      numberOfBalconies: schema.area?.numberOfBalconies,
      hasGarden: ifDefinedOtherwiseUndefined(schema.area?.hasGarden, () =>
        schema.area?.hasGarden ? PolarOptions.Yes : PolarOptions.No
      ),
      hasBalcony: ifDefinedOtherwiseUndefined(schema.area?.hasBalcony, () =>
        schema.area?.hasBalcony ? PolarOptions.Yes : PolarOptions.No
      ),
      hasLoggia: ifDefinedOtherwiseUndefined(schema.area?.hasLoggia, () =>
        schema.area?.hasLoggia ? PolarOptions.Yes : PolarOptions.No
      ),
      hasTerrace: ifDefinedOtherwiseUndefined(schema.area?.hasTerrace, () =>
        schema.area?.hasTerrace ? PolarOptions.Yes : PolarOptions.No
      ),
      hasOutsideArea: ifDefinedOtherwiseUndefined(
        schema.area?.hasOutsideArea,
        () => (schema.area?.hasOutsideArea ? PolarOptions.Yes : PolarOptions.No)
      ),
      bathroom: ifDefinedOtherwiseUndefined(
        schema.fitting?.bathroom,
        () =>
          schema.fitting?.bathroom
            ?.map((bathroom) =>
              switchCaseMap(
                bathroom,
                reverseItems(this.maps.bathroom) as [Bathroom, BathroomOption][]
              )
            )
            .filter((value) => value != null) as BathroomOption[]
      ),
      balconyOrientation: ifDefinedOtherwiseUndefined(
        schema.fitting?.balconyOrientation,
        () =>
          ifArrayNotEmpty(
            schema.fitting?.balconyOrientation
              ?.map((orientation) =>
                switchCaseMap(
                  orientation,
                  reverseItems(this.maps.balconyOrientation) as [
                    BalconyTerraceOrientation,
                    OrientationOptions
                  ][]
                )
              )
              .filter((f) => f != null) as OrientationOptions[]
          )
      ),
      loggiaOrientation: ifDefinedOtherwiseUndefined(
        schema.fitting?.loggiaOrientation,
        () =>
          ifArrayNotEmpty(
            schema.fitting?.loggiaOrientation
              ?.map((orientation) =>
                switchCaseMap(
                  orientation,
                  reverseItems(this.maps.loggiaOrientation) as [
                    BalconyTerraceOrientation,
                    OrientationOptions
                  ][]
                )
              )
              .filter((f) => f != null) as OrientationOptions[]
          )
      ),

      terraceOrientation: ifDefinedOtherwiseUndefined(
        schema.fitting?.terraceOrientation,
        () =>
          ifArrayNotEmpty(
            schema.fitting?.terraceOrientation
              ?.map((orientation) =>
                switchCaseMap(
                  orientation,
                  reverseItems(this.maps.terraceOrientation) as [
                    BalconyTerraceOrientation,
                    OrientationOptions
                  ][]
                )
              )
              .filter((f) => f != null) as OrientationOptions[]
          )
      ),
      gardenOrientation: undefined,
      balconyArea: schema.area?.balconyArea,
      loggiaArea: schema.area?.loggiaArea,
      gardenArea: schema.area?.gardenArea,
      terraceArea: schema.area?.terraceArea,
      numberOfTerrace: schema.area?.numberOfTerrace,
      numberOfLoggia: schema.area?.numberOfLoggia,

      shapeOfRoof: ifDefinedOtherwiseUndefined(
        schema.fitting?.shapeOfRoof,
        () =>
          schema.fitting?.shapeOfRoof
            ?.map((shapeOfRoof) =>
              switchCaseMap(
                shapeOfRoof,
                reverseItems(this.maps.shapeOfRoof) as [
                  roofTypesSchema,
                  roofTypes
                ][]
              )
            )
            .filter((value) => value != null) as roofTypes[]
      ),
      accessibility: ifDefinedOtherwiseUndefined(
        schema.fitting?.accessibility,
        () =>
          schema.fitting?.accessibility
            ?.map((accessibility) =>
              switchCaseMap(
                accessibility,
                reverseItems(this.maps.accessibility) as [
                  MiscellaneousOptions,
                  Miscellaneous
                ][]
              )
            )
            .filter((value) => value != null) as Miscellaneous[]
      ),
      facilities: ifDefinedOtherwiseUndefined(
        schema.fitting?.facilities,
        () =>
          schema.fitting?.facilities
            ?.map((facilities) =>
              switchCaseMap(
                facilities,
                reverseItems(this.maps.facilities) as [
                  MiscellaneousOptions,
                  Miscellaneous
                ][]
              )
            )
            .filter((value) => value != null) as Miscellaneous[]
      ),

      furnishing: ifDefinedOtherwiseUndefined(schema.fitting?.furnishing, () =>
        switchCaseMap(
          schema.fitting?.furnishing,
          reverseItems(this.maps.furnishing) as [Furnishing, furnishing][]
        )
      ),
      view: ifDefinedOtherwiseUndefined(
        schema.fitting?.view,
        () =>
          schema.fitting?.view
            ?.map((view) =>
              switchCaseMap(
                view,
                reverseItems(this.maps.view) as [View, ViewOptions][]
              )
            )
            .filter((value) => value != null) as ViewOptions[]
      ),
      constructionMethod: ifDefinedOtherwiseUndefined(
        schema.fitting?.constructionMethod,
        () =>
          schema.fitting?.constructionMethod
            ?.map((constructionMethod) =>
              switchCaseMap(
                constructionMethod,
                reverseItems(this.maps.constructionMethod) as [
                  RoutersListingModelConstructionType,
                  Construction
                ][]
              )
            )
            .filter((value) => value != null) as Construction[]
      ),

      firingType: ifDefinedOtherwiseUndefined(
        schema.fitting?.firingType,
        () =>
          schema.fitting?.firingType
            ?.map((firingType) =>
              switchCaseMap(
                firingType,
                reverseItems(this.maps.firingType) as [
                  FiringType,
                  FiringTypeOptions
                ][]
              )
            )
            .filter((value) => value != null) as FiringTypeOptions[]
      ),
      heatingType: ifDefinedOtherwiseUndefined(
        schema.fitting?.heatingType,
        () =>
          schema.fitting?.heatingType
            ?.map((heatingType) =>
              switchCaseMap(
                heatingType,
                reverseItems(this.maps.heatingType) as [
                  HeatingType,
                  HeatingTypeOptions
                ][]
              )
            )
            .filter((value) => value != null) as HeatingTypeOptions[]
      ),
      kitchen: ifDefinedOtherwiseUndefined(
        schema.fitting?.kitchen,
        () =>
          schema.fitting?.kitchen
            ?.map((kitchen) =>
              switchCaseMap(
                kitchen,
                reverseItems(this.maps.kitchen) as [Kitchen, KitchenOptions][]
              )
            )
            .filter((value) => value != null) as KitchenOptions[]
      ),
      // parkingSpace: ifDefinedOtherwiseUndefined(
      //   schema.fitting?.parkingSpace,
      //   () => {
      //     const parkingSpace = schema.fitting?.parkingSpace;
      //     return Array.isArray(parkingSpace)
      //       ? (parkingSpace.map((parkingSpace) =>
      //           switchCaseMap(
      //             parkingSpace,
      //             reverseItems(this.maps.parkingSpace) as [
      //               ParkingSpace,
      //               ParkingSpaceOptions
      //             ][]
      //           )
      //         ) as ParkingSpaceOptions[])
      //       : [];
      //   }
      // ),
      basementGarage: ifDefinedOtherwiseUndefined(
        schema.fitting?.parking?.basementGarage?.inPlace,
        () =>
          schema.fitting?.parking?.basementGarage?.inPlace
            ? PolarOptions.Yes
            : PolarOptions.No
      ),
      carport: ifDefinedOtherwiseUndefined(
        schema.fitting?.parking?.carport?.inPlace,
        () =>
          schema.fitting?.parking?.carport?.inPlace
            ? PolarOptions.Yes
            : PolarOptions.No
      ),
      duplex: ifDefinedOtherwiseUndefined(
        schema.fitting?.parking?.duplex?.inPlace,
        () =>
          schema.fitting?.parking?.duplex?.inPlace
            ? PolarOptions.Yes
            : PolarOptions.No
      ),
      garage: ifDefinedOtherwiseUndefined(
        schema.fitting?.parking?.garage?.inPlace,
        () =>
          schema.fitting?.parking?.garage?.inPlace
            ? PolarOptions.Yes
            : PolarOptions.No
      ),
      parkingSpace: ifDefinedOtherwiseUndefined(
        schema.fitting?.parking?.parkingSpace?.inPlace,
        () =>
          schema.fitting?.parking?.parkingSpace?.inPlace
            ? PolarOptions.Yes
            : PolarOptions.No
      ),
      freeParkingSpace: ifDefinedOtherwiseUndefined(
        schema.fitting?.parking?.freeParkingSpace?.inPlace,
        () =>
          schema.fitting?.parking?.freeParkingSpace?.inPlace
            ? PolarOptions.Yes
            : PolarOptions.No
      ),
      parkingDeck: ifDefinedOtherwiseUndefined(
        schema.fitting?.parking?.unknown?.inPlace,
        () =>
          schema.fitting?.parking?.unknown?.inPlace
            ? PolarOptions.Yes
            : PolarOptions.No
      ),
      numberOfParkingSpaces: schema.fitting?.parking?.parkingSpace?.count,
      numberOfBasementGarageSpaces:
        schema.fitting?.parking?.basementGarage?.count,
      numberOfCarportSpaces: schema.fitting?.parking?.carport?.count,
      numberOfDuplexSpaces: schema.fitting?.parking?.duplex?.count,
      numberOfGarageSpaces: schema.fitting?.parking?.garage?.count,
      numberOfParkingDeckSpaces: schema.fitting?.parking?.unknown?.count,
      handicappedAccessible: ifDefinedOtherwiseUndefined(
        schema.fitting?.accessibility,
        () =>
          schema.fitting?.accessibility?.includes(
            Accessibility.HANDICAPPED_ACCESSIBLE
          )
            ? PolarOptions.Yes
            : PolarOptions.No
      ),
      swimmingPool: ifDefinedOtherwiseUndefined(
        schema.fitting?.facilities,
        () =>
          schema.fitting?.facilities?.includes(Facilities.SWIMMING_POOL)
            ? PolarOptions.Yes
            : PolarOptions.No
      ),

      winterGarden: ifDefinedOtherwiseUndefined(
        schema.fitting?.facilities,
        () =>
          schema.fitting?.facilities?.includes(Facilities.WINTER_GARDEN)
            ? PolarOptions.Yes
            : PolarOptions.No
      ),
      shutter: ifDefinedOtherwiseUndefined(schema.fitting?.facilities, () =>
        schema.fitting?.facilities?.includes(Facilities.SHUTTER)
          ? PolarOptions.Yes
          : PolarOptions.No
      ),
      gardenAvailable: ifDefinedOtherwiseUndefined(
        schema.fitting?.facilities,
        () =>
          schema.fitting?.facilities?.includes(Facilities.gardenAvailable)
            ? PolarOptions.Yes
            : PolarOptions.No
      ),
      balconyAvailable: ifDefinedOtherwiseUndefined(
        schema.fitting?.facilities,
        () =>
          schema.fitting?.facilities?.includes(Facilities.balconyAvailable)
            ? PolarOptions.Yes
            : PolarOptions.No
      ),
      terrasseAvailable: ifDefinedOtherwiseUndefined(
        schema.fitting?.facilities,
        () =>
          schema.fitting?.facilities?.includes(Facilities.terrasseAvailable)
            ? PolarOptions.Yes
            : PolarOptions.No
      ),
      parkingSpaceAvailable: ifDefinedOtherwiseUndefined(
        schema.fitting?.parking,
        () => {
          if (
            schema?.fitting?.parking?.basementGarage?.inPlace ||
            schema?.fitting?.parking?.carport?.inPlace ||
            schema?.fitting?.parking?.duplex?.inPlace ||
            schema?.fitting?.parking?.garage?.inPlace ||
            schema?.fitting?.parking?.pakringDeck?.inPlace ||
            schema?.fitting?.parking?.parkingSpace?.inPlace ||
            schema?.fitting?.parking?.unknown?.inPlace
          ) {
            return PolarOptions.Yes;
          } else {
            return PolarOptions.No;
          }
        }
      ),

      loggiaAvailable: ifDefinedOtherwiseUndefined(
        schema.fitting?.facilities,
        () =>
          schema.fitting?.facilities?.includes(Facilities.loggiaAvailable)
            ? PolarOptions.Yes
            : PolarOptions.No
      ),

      isOwner: ifDefinedOtherwiseUndefined(
        schema.meta?.IREENUser?.isOwner,
        () =>
          schema.meta?.IREENUser?.isOwner ? PolarOptions.Yes : PolarOptions.No
      ),
      kind: ifDefinedOtherwiseUndefined(schema.object, () => {
        if (schema.object?.isRented) {
          return kind.UNKNOWN;
        } else if (schema.object?.isRentedLimited) {
          return kind.LIMITED;
        } else if (schema.object?.isRentedUnlimited) {
          return kind.INDEFINITE;
        }
      }),

      rentPrice: schema.priceInformation?.IREENUser?.currentMainRent,
      rentDate: schema.priceInformation?.IREENUser?.currentRentContractStart,
      fixedTermLease: ifDefinedOtherwiseUndefined(
        schema.priceInformation?.IREENUser?.leaseholdTerm,
        () =>
          schema.priceInformation?.IREENUser?.leaseholdTerm
            ? PolarOptions.Yes
            : PolarOptions.No
      ),
      intendedSellingPrice:
        schema.priceInformation?.IREENUser?.intendedSellingPrice,
      fixedTermLeaseDate: schema.priceInformation?.IREENUser?.leaseholdTermEnd,
      transactionPriceHistory:
        schema.priceInformation?.IREENUser?.transactionPriceHistory
    };
  }

  static calculateNetCosts(cost?: number, tax?: number) {
    if (!cost || !tax) {
      return undefined;
    }
    const value = cost / (1 + tax / 100);

    return Number(value.toFixed(2));
  }

  static calculateTaxCosts(isGross: boolean, cost?: number, tax?: number) {
    if (!cost || !tax) {
      return undefined;
    }
    const value = isGross ? cost * (1 - 100 / (100 + tax)) : (cost * tax) / 100;

    return Number(value.toFixed(2));
  }

  static calculateGrossCosts(cost?: number, tax?: number) {
    if (!cost || !tax) {
      return undefined;
    }
    const value = cost + tax;

    return Number(value.toFixed(2));
  }

  static calculateTaxRate(cost?: number, tax?: number) {
    if (!cost || !tax) {
      return undefined;
    }
    const value = (tax / cost) * 100;

    return Number(value.toFixed(0));
  }

  get refinedData() {
    return RefinedEvaluation.mapToSchema(this.features);
  }

  get getFeature() {
    return <K extends keyof IRefinedEvaluation = keyof IRefinedEvaluation>(
      name: K
    ) => this.features[name];
  }

  get totalFeatures() {
    return Object.keys(this.features).reduce((acc, key) => {
      let shouldIncrement = true;

      if (key in RefinedEvaluation.dependantFeatureKeys) {
        const dependantKeys =
          RefinedEvaluation.dependantFeatureKeys[
            key as keyof IRefinedEvaluation
          ];
        if (dependantKeys) {
          if (dependantKeys[1] !== undefined) {
            shouldIncrement =
              this.getFeature(dependantKeys[0]) === dependantKeys[1];
          } else {
            shouldIncrement = this.getFeature(dependantKeys[0]) !== undefined;
          }
        }
      }

      if (shouldIncrement) {
        return acc + 1;
      } else {
        return acc;
      }
    }, 0);
  }

  get countFeatures() {
    return Object.keys(this.features).reduce<number>((acc, key) => {
      const value = this.features[key as keyof IRefinedEvaluation];

      if (value !== undefined) {
        return acc + 1;
      } else {
        return acc;
      }
    }, 0);
  }

  @Mutation
  setRefinedData(refinedEvaluation: EditEvaluationRequestSchema) {
    Object.assign(
      this.features,
      RefinedEvaluation.mapToLocalState(refinedEvaluation)
    );
  }

  @Debounce(1000)
  @Mutation
  setFeature<K extends keyof IRefinedEvaluation = keyof IRefinedEvaluation>({
    name,
    value
  }: {
    name: K;
    value: IRefinedEvaluation[K];
  }) {
    this.features[name] = value;
  }

  @Mutation
  setShowForm(showForm: boolean) {
    this.showForm = showForm;
  }
}
