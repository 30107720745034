import posthog from 'posthog-js'
import Vue from 'vue'
import * as Sentry from '@sentry/browser'

export default function ({ app: { router, store } }, inject) {
  // Init PostHog
  posthog.init('phc_HD6iDjjv5Yn8dtieT15pPLuNqBHKK9z80WbfB2mvMv6', {
    api_host: 'https://rpph.ireen.com',
    capture_pageview: false,
    loaded: () => { store.$fire.auth?.currentUser?.email ? posthog.identify(store.$fire.auth?.currentUser?.email) : posthog.identify('anonymouse') }
  })

  Sentry.init({
    dsn: 'https://ebc7b95c9c471656eafcad21537f8c99@o4505778741968896.ingest.sentry.io/4505786957168640',
    integrations: [new posthog.SentryIntegration(posthog, 'https://ebc7b95c9c471656eafcad21537f8c99@o4505778741968896.ingest.sentry.io/', '4505786957168640')],
    environment: !process.env.PRODUCTION ? 'development' : 'production',
    ignore: [
      'node_modules',
      '.nuxt/dist/client/img'
    ]
  })
  // Inject PostHog into the application and make it available via this.$posthog (or app.$posthog)
  inject('posthog', posthog)

  // Make sure that pageviews are captured with each route change
  router.afterEach((to) => {
    Vue.nextTick(() => {
      /* Note: this might also be a good place to call posthog.register(...) in order to update your properties
      on each page view
      */
      if (store.$fire.auth?.currentUser?.email) {
        posthog.identify(store.$fire.auth?.currentUser?.email)
      }
      posthog.capture('$pageview', {
        $current_url: to.fullPath
      })
    })
  })
}
